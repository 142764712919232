import { createContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { EscrowOverdueOrder, EscrowOverdueOrdersData } from "./types";
import { MRT_TableInstance } from "material-react-table";

// ----------------------------------------------------------------------

const EscrowOverdueOrdersContext = createContext<EscrowOverdueOrdersData>({} as EscrowOverdueOrdersData);

function EscrowOverdueOrdersContextProvider({ children }: { children: JSX.Element }) {
  const [loading, setLoading] = useState(true);
  const [escrowOverdueOrders, setEscrowOverdueOrders] = useState<EscrowOverdueOrder[]>([]);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(true);

  const tableRef = useRef<MRT_TableInstance<EscrowOverdueOrder> | null>(null);
  const fetchEscrowOverdueOrders = async (useLoader: boolean = true) => {
    if (useLoader) {
      setLoading(true);
    }
    const { data } = await axios.get<EscrowOverdueOrder[]>(`/api/outstandingchecks/oldGetEscrowOverdueOrders`);
    setEscrowOverdueOrders(data);
    if (useLoader) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEscrowOverdueOrders(true);
  }, []);

  return (
    <EscrowOverdueOrdersContext.Provider
      value={{
        loading,
        escrowOverdueOrders,
        setEscrowOverdueOrders,
        assignedToMe,
        setAssignedToMe,
        fetchEscrowOverdueOrders,
        tableRef
      }}
    >
      {children}
    </EscrowOverdueOrdersContext.Provider>
  );
}

export { EscrowOverdueOrdersContextProvider, EscrowOverdueOrdersContext };
