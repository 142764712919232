import {
  Autocomplete,
  Chip,
  Container,
  InputAdornment,
  Link,
  Skeleton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { ClientOrder, Contact } from "../types/ClientOrder";
import { useEffect, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { DateRange, DateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { fMonthDayYearSlashNotation } from "../../utils/formatTime";
import { fCurrency } from "../../utils/formatNumber";
import ClientOrdersLoadingSkeleton from "./ClientOrdersLoadingSkeleton";
import Iconify from "../../../minimals/components/iconify";
import { debounce } from "lodash";
import InternalUserAutocomplete from "../../shared/InternalUserAutocomplete";
import { InternalUser } from "../../types/app";
import SalesPersonAutocomplete from "./SalesPersonAutocomplete";

const today = dayjs();
const defaultEndDate = today;
const defaultStartDate = today.subtract(2, "weeks");

const getChipColor = (transactionType: string) => {
  switch (transactionType) {
    case "Purchase":
      return "info";
    case "Refinance":
      return "warning";
    default:
      return "default";
  }
};

export default function ClientOrdersNew() {
  const [clientOrders, setClientOrders] = useState<ClientOrder[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<ClientOrder[]>([]);
  const [orderNumber, setOrderNumber] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([defaultStartDate, defaultEndDate]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [filter, setFilter] = useState<string>("Missing Deal Owners");
  const [transactionTypeFilter, setTransactionTypeFilter] = useState<string>("Commercial");
  const [statusFilter, setStatusFilter] = useState<string>("Closed");
  const [orderNumbers, setOrderNumbers] = useState<string[]>([]);
  const [loadingOrderNumbers, setLoadingOrderNumbers] = useState<boolean>(false);

  const searchClientOrders = async (): Promise<void> => {
    const params = orderNumber
      ? { orderNumber }
      : {
          from: dateRange[0]?.toDate(),
          to: dateRange[1]?.toDate(),
          orderNumber,
          commercial: transactionTypeFilter === "Commercial",
          status: statusFilter
        };
    setLoading(true);

    const { data } = await axios.get<ClientOrder[]>("/api/orders/getClientOrders", { params });
    const sortedOrders = data.sort((a, b) => {
      const dateA = new Date(a.closingDate);
      const dateB = new Date(b.closingDate);
      return dateA.getTime() - dateB.getTime();
    });

    setClientOrders(sortedOrders);
    triggerFilter(sortedOrders);
    setLoading(false);
  };

  const triggerFilter = (orders: ClientOrder[]) => {
    let filtered = orders;
    if (!orderNumber) {
      switch (filter) {
        case "Missing Deal Owners":
          filtered = orders.filter(
            (o) => (!o.dealOwnerLookupCodes || o.dealOwnerLookupCodes.length === 0) && o.fulfill
          );
          break;
        case "Contains Deal Owners":
          filtered = orders.filter((o) => o.dealOwnerLookupCodes?.length > 0);
          break;
        case "Active":
          filtered = orders.filter((o) => o.fulfill);
          break;
        case "Inactive":
          filtered = orders.filter((o) => !o.fulfill);
          break;
        case "Power BI Ignored":
          filtered = orders.filter((o) => !o.reporting);
          break;
        default:
          filtered = orders;
      }
    }

    setFilteredOrders(filtered);
    setPageNumber(1);
  };

  const setPrimaryContactOnOrder = (clientOrder: ClientOrder) => {
    const payload = {
      orderNumber: clientOrder.number,
      lookupCodes: clientOrder.dealOwnerLookupCodes
    };
    axios.post<ClientOrder[]>("/api/orders/setClientOnOrder", payload);
  };

  const setFulfillOnOrder = (clientOrder: ClientOrder) => {
    const payload = {
      orderNumber: clientOrder.number,
      value: clientOrder.fulfill
    };
    axios.post<ClientOrder[]>("/api/orders/setFulfillOnOrder", payload);
  };

  const handleContactChange = (clientOrder: ClientOrder, contacts: Contact[]) => {
    const updatedOrder = {
      ...clientOrder,
      dealOwnerLookupCodes: contacts.map((c) => c.lookupCode)
    } as ClientOrder;
    const updatedOrders = clientOrders.map((o) => {
      if (o.number === clientOrder.number) {
        return updatedOrder;
      } else {
        return o;
      }
    });
    setClientOrders(updatedOrders);
    setPrimaryContactOnOrder(updatedOrder);
  };

  const paginatedOrders = useMemo(() => {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredOrders.slice(startIndex, endIndex);
  }, [filteredOrders, pageNumber, pageSize]);

  const debouncedOrderSearchTextChange = debounce(async (text: string | null) => {
    if (!text) {
      setOrderNumbers([]);
      return;
    }
    setLoadingOrderNumbers(true);
    const { data } = await axios.get(`/proxy/api/orders/findRelatedOrdersByNumber?searchText=${text}`);
    setOrderNumbers(data);
    setLoadingOrderNumbers(false);
  }, 300);

  const findContact = (contacts: Contact[], lookupCodes: string[]) => {
    return contacts.filter((contact) => lookupCodes?.find((c) => c === contact.lookupCode));
  };

  const setReportingOnOrder = (clientOrder: ClientOrder) => {
    const payload = {
      orderNumber: clientOrder.number,
      value: clientOrder.reporting
    };
    axios.post<ClientOrder[]>("/api/orders/setReportingOnOrder", payload);
  };

  const handleFulfillChange = (clientOrder: ClientOrder, checked: boolean) => {
    const updatedOrder = {
      ...clientOrder,
      fulfill: checked
    } as ClientOrder;
    const updatedOrders = clientOrders.map((o) => {
      if (o.number === clientOrder.number) {
        return updatedOrder;
      } else {
        return o;
      }
    });
    setClientOrders(updatedOrders);
    setFulfillOnOrder(updatedOrder);
    triggerFilter(updatedOrders);
  };

  const handleReportingChange = (clientOrder: ClientOrder, checked: boolean) => {
    const updatedOrder = {
      ...clientOrder,
      reporting: checked
    } as ClientOrder;
    const updatedOrders = clientOrders.map((o) => {
      if (o.number === clientOrder.number) {
        return updatedOrder;
      } else {
        return o;
      }
    });
    setClientOrders(updatedOrders);
    setReportingOnOrder(updatedOrder);
    triggerFilter(updatedOrders);
  };

  const updateOrdersSalesPersons = (clientOrder: ClientOrder, salesReps: InternalUser[]) => {
    const updatedOrders = clientOrders.map((o) => {
      if (o.number === clientOrder.number) {
        return { ...o, salesReps: salesReps.map((r) => r.fullName).join(",") };
      } else {
        return o;
      }
    });
    setClientOrders(updatedOrders);
    triggerFilter(updatedOrders);
  };

  useEffect(() => {
    if (!dateRange[0] || !dateRange[1]) {
      return;
    }
    searchClientOrders();
  }, [dateRange, orderNumber, transactionTypeFilter, statusFilter]);

  useEffect(() => {
    triggerFilter(clientOrders);
  }, [filter]);

  return (
    <>
      <Helmet>
        <title>File Deal Owner | TitleQ</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" spacing={2} marginBottom={2}>
          <Typography variant="h4" marginBottom={2} sx={{ textWrap: "nowrap" }}>
            File Deal Owner
          </Typography>
          <Autocomplete
            options={orderNumbers}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ width: 280 }}
                placeholder="Search Order Number"
                onChange={(e) => debouncedOrderSearchTextChange(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon={"eva:search-fill"} sx={{ color: "text.disabled", width: 20, height: 20 }} />
                    </InputAdornment>
                  )
                }}
              />
            )}
            onChange={async (e, val) => {
              setOrderNumber(val);
            }}
            loading={loadingOrderNumbers}
          />
          <DateRangePicker
            onChange={(value) => setDateRange(value)}
            calendars={3}
            slots={{ field: SingleInputDateRangeField }}
            sx={{ width: 260 }}
            defaultValue={dateRange}
          />
          <Autocomplete
            options={["Missing Deal Owners", "Contains Deal Owners", "Active", "Inactive", "All", "Power BI Ignored"]}
            renderInput={(params) => <TextField {...params} />}
            sx={{ width: 250 }}
            value={filter}
            onChange={(e, v) => setFilter(v ? v : "Missing Deal Owners")}
          />
          <Autocomplete
            options={["Commercial", "Residential"]}
            renderInput={(params) => <TextField {...params} />}
            sx={{ width: 250 }}
            value={transactionTypeFilter}
            onChange={(e, v) => setTransactionTypeFilter(v ? v : "Commercial")}
          />
          <Autocomplete
            options={["Closed", "Open", "All"]}
            renderInput={(params) => <TextField {...params} />}
            sx={{ width: 250 }}
            value={statusFilter}
            onChange={(e, v) => setStatusFilter(v ? v : "Closed")}
          />
        </Stack>

        {loading && <ClientOrdersLoadingSkeleton />}

        {!loading && (
          <>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>File</TableCell>
                    <TableCell>Transaction</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Closed</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Deal Owner</TableCell>
                    <TableCell>Sales Person</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Power BI</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedOrders.map((row) => (
                    <TableRow key={row.number}>
                      <TableCell width={120}>
                        <Link href={`https://internal.mcres.com/new-home/${row.number}`} target="_blank">
                          {row.number}
                        </Link>
                        {loading && <Skeleton />}
                      </TableCell>
                      <TableCell>
                        <Chip label={row.transactionType} color={getChipColor(row.transactionType)} size="small" />
                      </TableCell>
                      <TableCell>
                        {row.transactionType === "Refinance" ? fCurrency(row.loanAmount) : fCurrency(row.purchasePrice)}
                      </TableCell>
                      <TableCell>
                        {/* Handle default date returned when null */}
                        {row.closingDate.toString() === "0001-01-01T00:00:00"
                          ? "--"
                          : fMonthDayYearSlashNotation(row.closingDate)}
                      </TableCell>
                      <TableCell>{row.state}</TableCell>
                      <TableCell width={480}>
                        <Autocomplete
                          multiple
                          fullWidth
                          size="medium"
                          options={row.contacts}
                          getOptionLabel={(option) =>
                            `${option.contactType} - ${option.name} ${`${option.isMarketingSource ? "⭐" : ""}`}`
                          }
                          renderInput={(params) => <TextField {...params} placeholder="Select Contact" />}
                          onChange={(event, value) => handleContactChange(row, value)}
                          onBlur={() => triggerFilter(clientOrders)}
                          defaultValue={findContact(row.contacts, row?.dealOwnerLookupCodes)}
                        />
                      </TableCell>
                      <TableCell width={480}>
                        {row.salesReps?.split(",").map((rep, index) => (
                          <Typography key={index} variant="body2">
                            {rep}
                          </Typography>
                        ))}
                        {!row.salesReps && (
                          <SalesPersonAutocomplete clientOrder={row} update={updateOrdersSalesPersons} />
                        )}
                      </TableCell>
                      <TableCell width={50}>
                        <Switch checked={row.fulfill} onChange={(e, checked) => handleFulfillChange(row, checked)} />
                      </TableCell>
                      <TableCell align="right" width={100}>
                        <Switch
                          checked={row.reporting}
                          onChange={(e, checked) => handleReportingChange(row, checked)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={filteredOrders.length}
              page={pageNumber - 1}
              onPageChange={(e, page) => setPageNumber(page + 1)}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(e) => setPageSize(parseInt(e.target.value, 10))}
              rowsPerPageOptions={[50, 100, 150]}
            />
          </>
        )}
      </Container>
    </>
  );
}
