import { EscrowLedger } from "./types";

export function processLedgerData(data: EscrowLedger[]) {
  data.forEach((d) => {
    const lastDash = d.name.lastIndexOf('-');
    d.orderNumber = d.name.substring(0, lastDash);
    d.friendlyName = d.name.substring(lastDash + 1);
    d.notes = d.notes
      .filter((n) => n.text)
      .map((n) => {
        const noteTextParts = n.text?.split("added this note: ");
        const createdByUser = noteTextParts?.length > 1 ? noteTextParts[0] : "API User";
        const text = noteTextParts?.length > 1 ? noteTextParts[1] : noteTextParts[0];
        return {
          ...n,
          createdByUser,
          text
        };
      });
  });
  return data;
}
