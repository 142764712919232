import { Alert, Button, Container, Grid, Stack, Typography } from "@mui/material";
import Page from "../../minimals/components/page";
import { useSettingsContext } from "../../minimals/components/settings";
import ContactInfo from "./ContactInfo";
import BulletinInfo from "./BulletinInfo";
import { useState } from "react";
import produce from "immer";
import { BlacklistNameEntity, BlacklistNameIndividual, BulletinInformation, UploadInfo } from "./types";
import { useNavigate } from "react-router";
import axios from "axios";
import { stripDataUriPrefix } from "../utils/stripDataUriPrefix";
import { toBase64 } from "../utils/helpers";

export default function AddBlacklistName() {
  const { themeStretch } = useSettingsContext();
  const baseIndivual = {
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    isAttorney: false,
    taxID: "",
    isNotary: false,
    yearAdmittedToBar: "",
    notes: ""
  };

  const baseEntity = {
    name: "",
    companyTypeId: { value: null, label: "" },
    taxID: "",
    notes: ""
  };
  const baseBulletin = {
    state: { id: 0, name: "", abbreviation: "", counties: [] },
    county: {
      id: 0,
      stateId: 0,
      name: "",
      state: {
        id: 0,
        name: "",
        abbreviation: "",
        counties: []
      }
    },
    note: ""
  };
  const [individualInfo, setIndividualInfo] = useState<BlacklistNameIndividual>(baseIndivual);
  const [entityInfo, setEntityInfo] = useState<BlacklistNameEntity>(baseEntity);
  const [bulletinInfo, setBulletinInfo] = useState<BulletinInformation>(baseBulletin);
  const [submitting, setSubmitting] = useState(false);
  const [uploads, setUploads] = useState<UploadInfo[]>([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isIndividual, setIsIndividual] = useState(true);
  const [nameError, setNameError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [bulletinStateError, setBulletinStateError] = useState(false);
  const [bulletinError, setBulletinError] = useState(false);
  const [notes, setNotes] = useState<string>("");

  const addOrEditUpload = (newUpload: UploadInfo, index?: number) => {
    if (index === undefined) {
      setUploads((prevUploads) => [...prevUploads, newUpload]);
      return;
    }
    setUploads((prevUploads) => {
      return prevUploads.map((pu, i) => {
        return i === index ? newUpload : pu;
      });
    });
  };
  const navigate = useNavigate();

  const changeIndividualInfo = (label: keyof BlacklistNameIndividual, value: any) => {
    const newIndividualInfo = produce(individualInfo, (draft: any) => {
      (draft[label] as keyof BlacklistNameIndividual) = value;
    });
    setIndividualInfo(newIndividualInfo);
  };

  const changeEntityInfo = (label: keyof BlacklistNameEntity, value: any) => {
    const newEntityInfo = produce(entityInfo, (draft: any) => {
      (draft[label] as keyof BlacklistNameEntity) = value;
    });
    setEntityInfo(newEntityInfo);
  };

  const changeBulletinInfo = (updates: Partial<BulletinInformation>) => {
    const newBulletinInfo = produce(bulletinInfo, (draft: any) => {
      for (const [key, value] of Object.entries(updates)) {
        (draft[key] as any) = value;
      }
    });
    setBulletinInfo(newBulletinInfo);
  };

  const handleRemoveUpload = (index: number) => {
    setUploads((prevUploads) => prevUploads.filter((_, i) => i !== index));
  };
  const resetFunction = () => {
    setSubmitting(false);
    setSuccessMessage(true);
    setBulletinInfo(baseBulletin);
    setIndividualInfo(baseIndivual);
    setEntityInfo(baseEntity);
    setUploads([]);
    setSuccessMessage(false);
    navigate("/blacklistsearch");
  };

  const onBlurIndividual = () => {
    if (individualInfo.lastName || individualInfo.taxID) {
      setNameError(false);
    }
    if (bulletinInfo.state.id) {
      setBulletinStateError(false);
    }
  }

  const onBlurEntity = () => {
    if (entityInfo.name || entityInfo.taxID) {
      setCompanyError(false);
    } 
    if (bulletinInfo.state.id) {
      setBulletinStateError(false);
    }
  }

  const onSubmit = async () => {
    if (isIndividual) {
      if (!individualInfo.lastName && !individualInfo.taxID) {
        setNameError(true);
      }
      if (!bulletinInfo.state.id) {
        setBulletinStateError(true);
      }
      if (uploads.length === 0) {
        setBulletinError(true);
      }
      if ((!individualInfo.lastName && !individualInfo.taxID) || !bulletinInfo.state.id || uploads.length === 0) {
        return;
      }
      const blacklistName = {
        person: { ...individualInfo, notes },
        countyId: bulletinInfo?.county?.id,
        stateId: bulletinInfo?.state?.id,
        bulletinsWithBase64: await Promise.all(
          uploads.map(async (u) => {
            const base64File = stripDataUriPrefix(await toBase64(u.file));
            return {
              bulletin: {
                number: u.number,
                date: u.date,
                //@ts-ignore
                underwriterId: u.underwriter.id
              },
              base64File
            };
          })
        )
      };
      setSubmitting(true);
      await axios.post("/api/blacklist/addPersonRecord", blacklistName);
      resetFunction();
      return;
    }
    if (!entityInfo.name && !entityInfo.taxID) {
      setCompanyError(true);
    } 
    if (!bulletinInfo.state.id) {
      setBulletinStateError(true);
    }
    if (uploads.length === 0) {
      setBulletinError(true);
    }
    if ((!entityInfo.name && !entityInfo.taxID) || !bulletinInfo.state.id || uploads.length === 0) {
      return;
    }
    const blacklistNameEntity = {
      company: {
        ...entityInfo,
        notes,
        //@ts-ignore
        companyTypeId: entityInfo?.companyTypeId?.id || null
      },
      countyId: bulletinInfo.county?.id,
      stateId: bulletinInfo.state?.id,
      bulletinsWithBase64: await Promise.all(
        uploads.map(async (u) => {
          const base64File = stripDataUriPrefix(await toBase64(u.file));
          return {
            bulletin: {
              number: u.number,
              date: u.date,
              //@ts-ignore
              underwriterId: u.underwriter.id
            },
            base64File
          };
        })
      )
    };
    setSubmitting(true);
    await axios.post("/api/blacklist/addCompanyRecord", blacklistNameEntity);
    resetFunction();
  };
  const onCancel = () => {
    navigate("/blacklistsearch");
  };

  return (
    <Page title="Blacklist" width="100%">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4" marginBottom={2}>
                Add Name to Blacklist
              </Typography>
            </Stack>
            <ContactInfo
              individualInfo={individualInfo}
              setIndividualInfo={changeIndividualInfo}
              entityInfo={entityInfo}
              changeEntityInfo={changeEntityInfo}
              isIndividual={isIndividual}
              setIsIndividual={setIsIndividual}
              nameError={nameError}
              companyError={companyError}
              onBlurIndividual={onBlurIndividual}
              onBlurEntity={onBlurEntity}
            />
            <BulletinInfo
              bulletinInfo={bulletinInfo}
              setBulletinInfo={changeBulletinInfo}
              addUpload={addOrEditUpload}
              uploads={uploads}
              handleRemoveUpload={handleRemoveUpload}
              bulletinStateError={bulletinStateError}
              notes={notes}
              setNotes={setNotes}
              onBlurIndividual={onBlurIndividual}
              bulletinError={bulletinError}
              setBulletinError={setBulletinError}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="right" spacing={1.5} width={784} marginTop={8}>
          <Button variant="outlined" color="inherit" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={submitting}>
            {submitting ? "Saving..." : "Save"}
          </Button>
          {(nameError || companyError) && <Typography sx={{ color: "red" }}>Missing Information</Typography>}
          {successMessage && (
            <Alert sx={{ marginTop: 2 }} severity="success">
              Successfully Saved
            </Alert>
          )}
        </Stack>
      </Container>
    </Page>
  );
}
