import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { MRT_TableInstance } from "material-react-table";
import { InternalUser } from "../../../types/app";

export enum DashboardReportType {
  OutstandingChecks = 1,
  PoliciesNotIssued = 2,
  EscrowOverdue = 3,
  NonZeroLedgerBalances = 4,
  PoliciesToSend = 5
}

export interface IAssignable {
  priority?: string;
  name?: string;
  selectId: number;
  assignedToUser?: InternalUser | null;
  assignedTo?: string;
  orderNumber: string;
  notes: DashboardNote[];
}

export interface Policy {
    id?: number;
    orderNumber: string;
    closer: string;
    closerUser: InternalUser | null;
    assignedToUser: InternalUser | null;
    loanPolicyReadyToBeSent: boolean;
    loanPolicyAlreadySent: boolean;
    ownersPolicyReadyToBeSent: boolean;
    ownersPolicyAlreadySent: boolean;
    selectId: number;
    notes: DashboardNote[];
    fileType?: string;
}

export interface DashboardNote {
  id: number;
  selectId: number;
  orderNumber: string;
  text: string;
  dateCreated: Date;
  createdByUser: string;
  createdBy: string;
}

export interface AssignedTo {
  id: number;
  selectId: number;
  orderNumber: string;
  name: string;
  email: string;
}

export interface PoliciesData {
  setDateRange: Dispatch<SetStateAction<DateRange<dayjs.Dayjs>>>;
  loading: boolean;
  policies: Policy[];
  dateRange: DateRange<dayjs.Dayjs>;
  setPolicies: Dispatch<SetStateAction<Policy[]>>;
  assignedToMe: boolean;
  setAssignedToMe: Dispatch<SetStateAction<boolean>>;
  fetchPolicies: (params?: fetchPoliciesParams) => Promise<void>;
  tableRef: React.MutableRefObject<MRT_TableInstance<Policy> | null>;
}

interface fetchPoliciesParams {
  useLoader?: boolean | undefined;
  resetPagination?: boolean | undefined;
}

export interface User {
  id: number;
  name: string;
  email: string;
  username: string;
}

export interface assignedToResponse {
  policyId: number;
  notes: DashboardNote[];
  assignedTo: AssignedTo;
}
