import { Box, Typography } from "@mui/material";
import { fCurrency } from "../../../utils/formatNumber";

interface IProps {
  table: any;
}

export default function TotalAmount({ table }: IProps) {
  const rows = table.getPrePaginationRowModel().rows;

  const amount = rows.map((row: any) => row.original.amount).reduce((a: any, b: any) => a + b, 0);

  if (!amount) return <></>;
  return (
    <Box>
      <Typography color="#637381" display="inline-block" variant="subtitle1" marginRight={1}>
        Total Dollar amount:
      </Typography>
      <Typography variant="body1" display="inline-block">
        {amount ? fCurrency(amount) : null}
      </Typography>
    </Box>
  );
}
