import { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import axios from "axios";
import { InternalUser } from "../types/app";

const fetchUsers = async (search: string): Promise<InternalUser[]> => {
  try {
    const { data } = await axios.get(`/api/clientphonebook/internalusers/searchSelectUsers?searchText=${search}`);
    return data;
  } catch (error) {
    throw new Error("Failed to fetch users");
  }
};

interface InternalUserAutocompleteProps {
  value: InternalUser | InternalUser[] | null;
  onChange: (user: InternalUser | InternalUser[] | null) => void;
  label?: string;
  sx?: any;
  multiple?: boolean;
  onBlur?: () => void;
  size?: "small" | "medium";
  limitTags?: number;
  limitToSalesReps?: boolean;
  getOptionDisabled?: (option: InternalUser) => boolean;
}

const InternalUserAutocomplete = ({
  value,
  onChange,
  label = "",
  sx = {},
  multiple,
  onBlur,
  size,
  limitTags = 1,
  limitToSalesReps,
  getOptionDisabled
}: InternalUserAutocompleteProps) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<InternalUser[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchOptions = async (value: string) => {
    setLoading(true);
    try {
      const users = await fetchUsers(value);
      const filteredUsers = limitToSalesReps ? users.filter((u) => u.salesRepId) : users;
      setOptions(filteredUsers);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedInputChange = debounce(setInputValue, 300);

  useEffect(() => {
    if (inputValue) {
      fetchOptions(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  return (
    <Autocomplete
      limitTags={limitTags}
      loading={loading}
      options={options}
      multiple={multiple}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      onBlur={onBlur}
      getOptionLabel={(option) => option.fullName || ""}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          sx={sx}
          label={label || "Search Users"}
          size={size || "small"}
          inputProps={{ ...params.inputProps, autoComplete: "new-password" }}
          onChange={(event) => debouncedInputChange(event.target.value)}
        />
      )}
      getOptionDisabled={getOptionDisabled}
    />
  );
};

export default InternalUserAutocomplete;
