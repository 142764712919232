import { NonZeroBalanceLedger } from "./types";

export function processLedgerData(data: NonZeroBalanceLedger[]) {
  data.forEach((d) => {
    d.orderNumber = d.name;
    d.friendlyName = d.name;
    if(!d.orderInfo.department) {
      d.orderInfo.department = '';
    }
    d.notes = d.notes
      .filter((n) => n.text)
      .map((n) => {
        const noteTextParts = n.text?.split("added this note: ");
        const createdByUser = noteTextParts?.length > 1 ? noteTextParts[0] : "API User";
        const text = noteTextParts?.length > 1 ? noteTextParts[1] : noteTextParts[0];
        return {
          ...n,
          createdByUser,
          text
        };
      });
  });
  return data;
}
