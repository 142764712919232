import { Grid, Stack, TextField, FormControlLabel, Checkbox, Autocomplete } from "@mui/material";
import { useEffect } from "react";
import { BlacklistNameIndividual } from "./types";

interface Props {
  individualInfo: BlacklistNameIndividual;
  setIndividualInfo: (e: any, value: any) => void;
  nameError: boolean;
  onBlurIndividual: () => void;
}
export default function IndividualContactInfo(props: Props) {
  const titles = ["Mr.", "Ms.", "Mrs.", "Dr.", "Prof."];
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 81 }, (_, index) => (currentYear - index).toString());

  useEffect(() => {
    if (!props.individualInfo?.isAttorney) {
      props.setIndividualInfo("yearAdmittedToBar", null); 
    }
  }, [props.individualInfo?.isAttorney, props.setIndividualInfo]);
  return (
    <>
      <Stack direction="row" justifyContent="space-between" marginTop="40px" spacing={1}>
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={titles}
            renderInput={(params) => <TextField {...params} label="Title" />}
            onChange={(e, value) => {
              props.setIndividualInfo("title", value);
            }}
            value={props.individualInfo?.title}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <TextField
            label="First Name"
            value={props.individualInfo?.firstName}
            onChange={(e) => props.setIndividualInfo("firstName", e.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Middle"
            value={props.individualInfo?.middleName}
            onChange={(e) => props.setIndividualInfo("middleName", e.target.value)}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <TextField
            label="Last Name"
            value={props.individualInfo?.lastName}
            onChange={(e) => props.setIndividualInfo("lastName", e.target.value)}
            error={props.nameError}
            onBlur={props.onBlurIndividual}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label="Suffix"
            value={props.individualInfo?.suffix}
            onChange={(e) => props.setIndividualInfo("suffix", e.target.value)}
          />
        </Grid>
      </Stack>
      <Stack direction="row" marginTop="30px" alignItems={"center"}>
        <Grid item md={2} xs={12} sx={{ display: "flex" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.individualInfo?.isNotary}
                onChange={(e, value) => props.setIndividualInfo("isNotary", value)}
              />
            }
            label="Is notary"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Tax ID"
            value={props.individualInfo?.taxID}
            onChange={(e) => props.setIndividualInfo("taxID", e.target.value)}
            fullWidth
          />
        </Grid>
      </Stack>
      <Stack direction="row" marginTop="30px" alignItems={"center"}>
        <Grid item md={2} xs={12} sx={{ display: "flex" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.individualInfo?.isAttorney}
                onChange={(e, value) => props.setIndividualInfo("isAttorney", value)}
              />
            }
            label="Is attorney"
          />
        </Grid>
        {props.individualInfo?.isAttorney && (
          <Grid item md={6} xs={12}>
            <Autocomplete
              options={years}
              onChange={(e, value) => props.setIndividualInfo("yearAdmittedToBar", value)}
              value={props.individualInfo?.yearAdmittedToBar}
              renderInput={(params) => <TextField {...params} label="Admitted to Bar" placeholder="Admitted to Bar" />}
            />
          </Grid>
        )}
      </Stack>
    </>
  );
}
