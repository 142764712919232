import { Grid, Stack, TextField, Autocomplete } from "@mui/material";
import axios from "axios";
import produce from "immer";
import { useEffect, useState } from "react";
import { BlacklistNameEntity } from "./types";

interface Props {
  entityInfo: BlacklistNameEntity;
  changeEntityInfo: (e: any, value: any) => void;
  error: boolean;
  onBlurEntity: () =>  void;
}
export default function EntityContactInfo(props: Props) {
  const [companyTypes, setCompanyTypes] = useState<any[]>([]);

  const getCompanyTypes = async () => {
    const { data } = await axios.get("/api/blacklist/getCompanyTypes");
    setCompanyTypes(data);
  };

  useEffect(() => {
    getCompanyTypes();
  }, []);
  return (
    <>
      <Stack direction="row" justifyContent="space-between" marginTop="40px">
        <Grid item md={6} xs={12} spacing={2}>
          <TextField
            label="Company Name"
            value={props.entityInfo.name}
            onChange={(e) => props.changeEntityInfo("name", e.target.value)}
            fullWidth
            error={props.error}
            onBlur={props.onBlurEntity}
          />
        </Grid>
        <Grid item md={6} xs={12} marginLeft={2}>
          <Autocomplete
            options={companyTypes || []}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => <TextField {...params} label="Company Type(LLC, Corp, etc)" />}
            onChange={(e, value) => props.changeEntityInfo("companyTypeId", value)}
            value={props.entityInfo.companyTypeId || null}
          />
        </Grid>
      </Stack>
      <Grid item md={6} xs={12} marginTop={4}>
        <TextField
          label="Tax ID"
          value={props.entityInfo.taxID}
          onChange={(e) => props.changeEntityInfo("taxID", e.target.value)}
          fullWidth
        />
      </Grid>
    </>
  );
}
