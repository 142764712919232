import { Button, Link, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { Policy } from "./types";

export const getColumns = (handleOpenModal: (selectedPolicy: Policy)=> void ): MRT_ColumnDef<Policy>[] => {
  return [
    {
      id: "notes",
      header: "",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableColumnDragging: false,
      enableColumnFilterModes: false,
      size: 50,
      enableHiding: false
    },
    {
      header: "Order Number",
      accessorKey: "orderNumber",
      id: "orderNumber",
      Cell: ({ renderedCellValue, row }) => (
        <Link href={`https://internal.mcres.com/new-home/${row.original.orderNumber}`} target="_blank">
          <Typography variant="body2">{row.original.orderNumber}</Typography>
        </Link>
      )
    },
    {
      header: "Policy Type",
      accessorKey: "policyType",
      id: "policyType",
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Assigned to",
      accessorKey: "closer",
      id: "closer",
      Cell: ({ renderedCellValue, row }) => (
        <Typography variant="body2">{row.original.assignedToUser?.fullName}</Typography>
      ),
      filterVariant: "select",
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Status",
      filterVariant: "select",
      accessorKey: "Status",
      Cell: ({ renderedCellValue, row }) => (
        <div>
          <Button onClick={() => handleOpenModal(row.original)}>Upload Email</Button>
        </div>
      ),
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    }
  ];
};
