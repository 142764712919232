import { Autocomplete, Button, Link, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { MRT_ColumnDef } from "material-react-table";
import Iconify from "../../../../minimals/components/iconify";
import { Policy, PolicyIssue } from "./types";

const policyStatusOptions = [
  { value: 1, label: "Not Ready" },
  { value: 2, label: "Not Sent" },
  { value: 3, label: "Sent" }
];
const policyIssuesOptions = [
  { value: 1, label: "Unresolved" },
  { value: 2, label: "Resolved" },
  { value: 3, label: "WrittenOff" }
];

const getPolicyIssue = (value: any) => {
    const issue = policyIssuesOptions.find(option => option.value === value);
    return issue ? { value: issue.value, label: issue.label } : null;
  };
const onChangeIssue = async (policy: Policy) => {
  await axios.post("/api/outstandingChecks/UpdatePolicy", policy);
};

export const getColumns = (handleOpenModal: (selectedPolicy: Policy) => void): MRT_ColumnDef<Policy>[] => {
  return [
    {
      id: "notes",
      header: "",
      enableColumnFilter: false,
      enableColumnActions: false,
      enableColumnDragging: false,
      enableColumnFilterModes: false,
      size: 50,
      enableHiding: false
    },
    {
      header: "Policy Number",
      accessorKey: "policyNumber",
      id: "policyNumber",
      Cell: ({ renderedCellValue, row }) => <Typography variant="body2">{row.original.policyNumber}</Typography>
    },
    {
      header: "Order Number",
      accessorKey: "orderNumber",
      id: "orderNumber",
      Cell: ({ renderedCellValue, row }) => (
        <Link href={`https://internal.mcres.com/new-home/${row.original.orderNumber}`} target="_blank">
          <Typography variant="body2">{row.original.orderNumber}</Typography>
        </Link>
      )
    },
    {
      header: "Policy Type",
      accessorKey: "type",
      id: "type",
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      },
      Cell: ({ renderedCellValue, row }) => (
        <Stack direction={"row"}>
          {row.original.status !== 0 && (
            <Iconify sx={{ marginRight: 1, cursor: "pointer" }} icon={"eva:download-fill"} />
          )}
          <Typography variant="body2">
            {+row.original.type === 2 ? "Loan " : "Owners "}
            {row.original.position && row.original.position}
          </Typography>
        </Stack>
      )
    },
    {
      header: "Assigned to",
      accessorKey: "assignedToUser",
      id: "assignedToUser",
      Cell: ({ renderedCellValue, row }) => (
        <Typography variant="body2">{row.original.assignedToUser?.fullName}</Typography>
      ),
      filterVariant: "select",
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Issue",
      accessorKey: "policyIssue",
      id: "policyIssue",
      Cell: ({ renderedCellValue, row }) => (
        <Typography variant="body2">
          <Autocomplete
            options={policyIssuesOptions || []}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => <TextField {...params} label="Issues" />}
            onChange={(e, value) => onChangeIssue({ ...row.original, issue: value?.value})}
            value={getPolicyIssue(row.original.issue)}
          />
        </Typography>
      ),
      filterVariant: "select",
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Status",
      filterVariant: "select",
      accessorKey: "policyStatus",
      id: "policyStatus",
      Cell: ({ renderedCellValue, row }) => (
        <div>
          {row.original.status === 0 ? (
            <Button onClick={() => handleOpenModal(row.original)}>Upload Email</Button>
          ) : (
            <Autocomplete
              options={policyStatusOptions || []}
              getOptionLabel={(option) => option.label || ""}
              renderInput={(params) => <TextField {...params} label="Status" />}
              onChange={(e, value) => {}}
              //value={row.original.status.id || null}
            />
          )}
        </div>
      ),
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    }
  ];
};
