import DynamicLink from "../shared/DynamicLink";

const sampleBulletinNames = ["98765_20241220175215", "12345_20241220175215", 'fake'];

const ViewBulletins = () => {
  const openBulletin = (id: string, asDownload: boolean) => {
    window.open(`/api/blacklist/openBulletin?id=${id}&asDownload=${asDownload}`, "_blank");
  };

  return (
    <>
      {sampleBulletinNames.map((name) => {
        return (
          <div>
            <DynamicLink
              path={`/api/blacklist/openBulletin?id=${encodeURIComponent(name)}&asDownload=${false}`}
              target="_blank"
            >
              Open Document
            </DynamicLink>
            |
            <DynamicLink
              path={`/api/blacklist/openBulletin?id=${encodeURIComponent(name)}&asDownload=${true}`}
              target="_blank"
            >
              Download Document
            </DynamicLink>
          </div>
        );
      })}
    </>
  );
};

export default ViewBulletins;
