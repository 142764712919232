import { Suspense, lazy, ElementType, useEffect, useContext, ReactNode } from "react";
import { Navigate, useRoutes, useLocation, useNavigate } from "react-router-dom";
import PageOrderEntry from "../orderEntry/PageCreateOrder";
import PageSearchOrder from "../searches/PageOrderSearches";
import ClosingsPage from "../closings/ClosingsPage";
import PageConfirmation from "../confirmationForms/PageConfirmation";
import OrderNumberPrompt from "../orderEntry/OrderNumberPrompt";
import { logPageView } from "../utils/analyticsLogger";
import PageNotes from "../orderEntry/PageNotes";
import LoadingScreen from "../../minimals/components/loading-screen";
import DashboardLayout from "../components/layouts/dashboard";
import CalendarPage from "../calendar/Calendar";
import CreateNew from "../phonebook/shared/create/CreateNew";
import CompaniesPage from "../phonebook/company/CompaniesPage";
import ContactsPage from "../phonebook/contact/ContactsPage";
import ContactList from "../phonebook/contactsList/ContactList";
import AddEditContactForm from "../phonebook/contact/addEditContact/AddEditContactForm";
import AddEditCompanyForm from "../phonebook/company/addEditCompany/AddEditCompany";
import { CompanyProvider } from "../phonebook/company/CompanyContext";
import Checks from "../checks/Checks";
import { ChecksContextProvider } from "../checks/ChecksContext";
import { OutstandingSearchesContextProvider } from "../reports/outstandingSearches/OutstandingSearchesContext";
import OutstandingSearches from "../reports/outstandingSearches/OutstandingSearches";
import { SearchesNotOrderedContextProvider } from "../reports/searchesNotOrdered/SearchesNotOrderedContext";
import SearchesNotOrdered from "../reports/searchesNotOrdered/SearchesNotOrdered";
import { CommitmentsOverdueContextProvider } from "../reports/commitmentsOverdue/CommitmentsOverdueContext";
import CommitmentsOverdue from "../reports/commitmentsOverdue/CommitmentsOverdue";
import Dashboard from "../reports/dashboard/Dashboard";
import RecordablesPage from "../recordables/RecordablesPage";
import DepositRefundsPage from "../depositRefunds/DepositRefundsPage";
import NewDepositRefundRequest from "../depositRefunds/NewDepositRefundRequest";
import ManageTasksPage from "../recordables/manageTasks/ManageTasksPage";
import TasksPage from "../recordables/TasksPage";

import CorpDocsPage from "../corpdocs/CorpDocsPage";
import CorpDocsManageTasksPage from "../corpdocs/manageTasks/ManageTasksPage";
import CorpDocsTasksPage from "../corpdocs/TasksPage";

import MergeContacts from "../phonebook/contact/merge/MergeContacts";
import { PoliciesNotIssuedContextProvider } from "../reports/policiesNotIssued/PoliciesNotIssuedContext";
import PoliciesNotIssued from "../reports/policiesNotIssued/PoliciesNotIssued";
import EditContactHistory from "../admin/ContactHistory/EditContactHistory";
import RedFlags from "../admin/RedFlags/RedFlags";
import SuspectedMatches from "../admin/ContactHistory/SuspectedMatches";
import InsuranceAudit from "../insuranceAudit";
import AddCompanyPage from "../phonebook/company/AddCompanyPage";
import MergeCompaniesPage from "../phonebook/mergeCompanies/MergeCompaniesPage";
import PageLoginSucess from "../PageLoginSucess";
import NonZeroLedgerBalance_OLD from "../reports/nonZeroLedgerBalance_OLD";
import ManagersDashboard from "../reports/dashboard/ManagersDashboard";
import TMEOrders from "../orders/TMEOrders";
import BlackListNameSearch from "../components/BlacklistNameSearch";
import Tasks from "../taskManagement/Tasks";
import { OrderInfoProvider } from "../shared/orderInfo/OrderInfoContext";
import FlipsDashboard from "../taskManagement/flipsDashboard";
import { FlipsDashboardContextProvider } from "../contexts/FlipsDashboardContext";
import Directory from "../directory";
import InternalUserRoles from "../internalUserRoles";
import EditInternalUserRole from "../internalUserRoles/EditInternalUserRole";
import { UserContext } from "../contexts/UserContext";
import Unauthorized from "../unauthorized/Unauthorized";
import Page404 from "../Page404";
import ExecutiveReports from "../executiveReports/ExecutiveReports";
import MarkedUpCommitment from "../taskManagement/markedUpCommitment";
import CommissionTool from "../commissionTool";
import TransactionLedger from "../components/transactionLedger";
import { EscrowOverdueOrdersContextProvider } from "../reports/escrowOverdueOrders-old/EscrowOverdueOrdersContext";
import EscrowOverdueOrders from "../reports/escrowOverdueOrders-old/EscrowOverdueOrders";
import { EscrowLedgersContextProvider } from "../reports/escrowLedgers/EscrowLedgersContext";
import {RecordingLedgersContextProvider} from '../reports/recordingLedgers/RecordingLedgersContext';
import RecordingLedgers from '../reports/recordingLedgers/RecordingLedgers';
import EscrowLedgers from "../reports/escrowLedgers/EscrowLedgers";
import AddBlacklistName from "../Blacklist/AddBlacklistName";
import AddBlacklistProperty from "../Blacklist/AddBlacklistProperty";
import ClientOrders from "../orderEntry/clientOrders/ClientOrders";
import PoliciesToSendDashboard from "../reports/dashboard/policiestosenddashbord/PoliciesToSendDashboard";
import ViewBulletins from "../Blacklist/ViewBulletins";
import { PoliciesToSendProvider } from "../reports/dashboard/policiestosenddashbord/PoliciesToSendContext";
import { PolicyIssuesProvider } from "../reports/dashboard/policyIssues/PolicyIssuesContext";
import PolicyIssuesDashboard from "../reports/dashboard/policyIssues/PolicyIssues";
import {   NonZeroBalanceLedgersContextProvider} from "../reports/nonZeroBalanceLedgers/NonZeroBalanceLedgersContext";
import NonZeroBalanceLedgers from "../reports/nonZeroBalanceLedgers/NonZeroBalanceLedgers";


// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const ManagerProtectedRoute = ({ element }: { element: ReactNode }) => {
  const { user } = useContext(UserContext);
  const isManager = user?.isManager;
  const navigate = useNavigate();

  useEffect(() => {
    if (user != null && !isManager) {
      navigate("/unauthorized");
    }
  }, [user, isManager]);

  return <>{isManager ? element : null}</>;
};

export default function Router() {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to="/orders" replace />,
          index: true
        },
        {
          path: "directory",
          children: [
            {
              element: <Directory />,
              index: true
            }
          ]
        },
        {
          path: "orders",
          children: [
            {
              element: <TMEOrders />,
              index: true
            }
          ]
        },
        {
          path: "order-entry",
          children: [
            {
              element: <PageOrderEntry />,
              index: true
            }
          ]
        },
        {
          path: "order-entry/order-information",
          children: [
            {
              element: <PageOrderEntry />,
              index: true
            }
          ]
        },
        {
          path: "searches",
          children: [
            {
              element: <OrderNumberPrompt section="searches" />,
              index: true
            }
          ]
        },
        {
          path: "searches",
          children: [
            {
              path: ":orderNumber",
              element: <PageSearchOrder />,
              index: true
            }
          ]
        },
        {
          path: "confirmation",
          children: [
            {
              element: <OrderNumberPrompt section="confirmation" />,
              index: true
            }
          ]
        },
        {
          path: "notes",
          children: [
            {
              element: <OrderNumberPrompt section="notes" />,
              index: true
            }
          ]
        },
        {
          path: "confirmation",
          children: [
            {
              path: ":orderNumber",
              element: <PageConfirmation />,
              index: true
            }
          ]
        },
        {
          path: "notes",
          children: [
            {
              path: ":orderNumber",
              element: <PageNotes />,
              index: true
            }
          ]
        },
        {
          path: "contacts",
          children: [
            {
              element: <Navigate to="/phonebook/contacts/individuals" replace />,
              index: true
            }
          ]
        },
        {
          path: "calendar",
          children: [{ index: true, element: <CalendarPage /> }]
        },
        {
          path: "closings",
          children: [{ index: true, element: <ClosingsPage /> }]
        },
        {
          path: "/phonebook/contact",
          element: <Navigate to="/phonebook/contacts/profile" replace />
        },
        {
          path: "/phonebook/contact/:section",
          element: <ContactsPage />
        },
        {
          path: "/phonebook/contactsMerge",
          element: <MergeContacts />
        },
        {
          path: "/phonebook/companiesMerge",
          element: <MergeCompaniesPage />
        },
        {
          path: "/phonebook/company",
          element: <Navigate to="/phonebook/companies/profile" replace />
        },
        {
          path: "/phonebook/company/create-company",
          element: <AddCompanyPage />
        },

        {
          path: "/phonebook/company/:section",
          element: <CompaniesPage />
        },
        {
          path: "/phonebook/new",
          element: <CreateNew />
        },
        {
          path: "/phonebook/create-contact",
          element: <AddEditContactForm />
        },
        {
          path: "/phonebook/edit-contact",
          element: <AddEditContactForm />
        },
        {
          path: "/phonebook/edit-company",
          element: (
            <CompanyProvider>
              <AddEditCompanyForm />
            </CompanyProvider>
          )
        },
        {
          path: "/phonebook/new-contacts",
          element: <Navigate to="/phonebook/new-contacts/individuals" replace />
        },
        {
          path: "/phonebook",
          element: <Navigate to="/phonebook/contacts/individuals" replace />
        },
        {
          path: "/phonebook/contacts/:section",
          element: <ContactList />
        },
        {
          path: "/checks",
          element: (
            <ChecksContextProvider>
              <Checks />
            </ChecksContextProvider>
          )
        },
        {
          path: "/phonebook/edit-history",
          element: <EditContactHistory />
        },
        {
          path: "/phonebook/redflags",
          element: <RedFlags />
        },
        {
          path: "/phonebook/suspectedMatches",
          element: <SuspectedMatches />
        },
        {
          path: "busdev/order-clients",
          element: <ClientOrders />
        },
        {
          path: "/tasks",
          element: <Navigate to="/outstanding-searches" replace />
        },
        {
          path: "/outstanding-searches",
          element: (
            <OutstandingSearchesContextProvider>
              <OutstandingSearches />
            </OutstandingSearchesContextProvider>
          )
        },
        {
          path: "/searches-not-ordered",
          element: (
            <SearchesNotOrderedContextProvider>
              <SearchesNotOrdered />
            </SearchesNotOrderedContextProvider>
          )
        },
        {
          path: "/commitments-overdue",
          element: (
            <CommitmentsOverdueContextProvider>
              <CommitmentsOverdue />
            </CommitmentsOverdueContextProvider>
          )
        },
        {
          path: "/dashboard",
          element: <ManagersDashboard />
        },
        {
          path: "/recordables",
          element: (
            <OrderInfoProvider>
              <RecordablesPage />
            </OrderInfoProvider>
          )
        },
        {
          path: "/recordables/tasks",
          element: (
            <OrderInfoProvider>
              <TasksPage />
            </OrderInfoProvider>
          )
        },
        {
          path: "/recordables/manage-tasks",
          element: <ManageTasksPage />
        },
        {
          path: "/deposit-refunds/view",
          element: <DepositRefundsPage />
        },
        {
          path: "/deposit-refunds/new",
          element: <NewDepositRefundRequest />
        },
        {
          path: "/corpdocs",
          element: <CorpDocsPage />
        },
        {
          path: "/corpdocs/tasks",
          element: <CorpDocsTasksPage />
        },
        {
          path: "/corpdocs/manage-tasks",
          element: <CorpDocsManageTasksPage />
        },
        {
          path: "/policies-not-issued",
          element: (
            <PoliciesNotIssuedContextProvider>
              <PoliciesNotIssued />
            </PoliciesNotIssuedContextProvider>
          )
        },
        {
          path: "/escrow-overdue-orders",
          element: (
            <EscrowOverdueOrdersContextProvider>
              <EscrowOverdueOrders />
            </EscrowOverdueOrdersContextProvider>
          )
        },
        {
          path: "/escrow-ledgers",
          element: (
              <EscrowLedgersContextProvider>
                <EscrowLedgers />
              </EscrowLedgersContextProvider>
          )
        },
        {
          path: "/non-zero-ledger-balances",
          element: (
              <NonZeroBalanceLedgersContextProvider>
                <NonZeroBalanceLedgers />
              </NonZeroBalanceLedgersContextProvider>
          )
        },
        {
          path: "/recording-ledgers",
          element: (
              <RecordingLedgersContextProvider>
                <RecordingLedgers />
              </RecordingLedgersContextProvider>
          )
        },
        {
          path: "/old-non-zero-ledger-balances",
          element: <NonZeroLedgerBalance_OLD />
        },
        {
          path: "/flips-dashboard",
          element: (
            <FlipsDashboardContextProvider>
              <FlipsDashboard />
            </FlipsDashboardContextProvider>
          )
        },
        {
          path: "/insurance-audit",
          children: [
            {
              index: true,
              element: (
                <OrderInfoProvider>
                  <InsuranceAudit />
                </OrderInfoProvider>
              )
            }
          ]
        },
        {
          path: "/loginsuccess",
          element: <PageLoginSucess />
        },
        {
          path: "/dashboard2",
          element: <Dashboard />
        },
        {
          path: "/internal-user-roles",
          element: <ManagerProtectedRoute element={<InternalUserRoles />} />
        },
        {
          path: "/internal-user-roles/edit/:id",
          element: <ManagerProtectedRoute element={<EditInternalUserRole />} />
        },
        { path: "/unauthorized", element: <Unauthorized /> },
        { path: "/blacklistSearch", element: <BlackListNameSearch /> },
        { path: "/task-management", element: <Navigate to="/task-management/flips" replace /> },
        {
          path: "/task-management/closingchecklist/marked-up-commitment/:orderNumber",
          element: <MarkedUpCommitment />
        },
        {
          path: "/task-management/:taskType",
          element: (
            <OrderInfoProvider>
              <Tasks />
            </OrderInfoProvider>
          )
        },
        { path: "/operations/missing-principal", element: <ExecutiveReports /> },
        { path: "/commission-tool", element:  <CommissionTool/> },
        { path: "/ledger/:orderNumber", element:  <TransactionLedger/> },
        { path: "/add-blacklist-name", element:  <AddBlacklistName/> },
        { path: "/add-blacklist-property", element:  <AddBlacklistProperty/> },
        { path: "/policies-to-send", element: (
          <PoliciesToSendProvider>
            <PoliciesToSendDashboard />
          </PoliciesToSendProvider>
        )},
        { path: "/policy-issues", element: (
          <PolicyIssuesProvider>
            <PolicyIssuesDashboard />
          </PolicyIssuesProvider>
        )},
        { path: "/view-bulletins", element:  <ViewBulletins/> },
        {
          path: "*",
          element: <Page404 />
        }
      ]
    }
  ]);
}
