import axios from "axios";
import { MemberTaskCount } from "../../types";
import { Policy } from "../policiestosenddashbord/types";
import { countAndMapAssignedTasks } from "./commonUtils";

export const getPoliciesToSendUniqueAssignedToList = (
    policiesToSend: Policy[]
  ): MemberTaskCount[] => countAndMapAssignedTasks(policiesToSend, "Policies To Send");

export const searchPoliciesToSend = async () => {
  const { data } = await axios.get<Policy[]>(`/api/OutstandingChecks/GetPoliciesToSend`);
  const filterData: any = data
    .map((item) => {
      const rows = [];
      if (item.loanPolicyReadyToBeSent && !item.loanPolicyAlreadySent) {
        rows.push({
          orderNumber: item.orderNumber,
          closer: item.closer,
          policyType: "Loan",
          selectId: item.selectId,
          notes: item.notes,
          closerUser: item.closerUser,
          assignedToUser: item.assignedToUser
        });
      }
      if (item.ownersPolicyReadyToBeSent && !item.ownersPolicyAlreadySent) {
        rows.push({
          orderNumber: item.orderNumber,
          closer: item.closer,
          policyType: "Owners",
          selectId: item.selectId,
          notes: item.notes,
          closerUser: item.closerUser,
          assignedToUser: item.assignedToUser
        });
      }
      return rows.length > 0 ? rows : null;
    })
    .flat()
    .filter(Boolean);
  return filterData;
};
