import { Box, Button, Container, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MaterialReactTable, MRT_TableInstance } from "material-react-table";
import { columns } from "./columns";
import { useContext, useEffect, useMemo, useState } from "react";
import { NonZeroBalanceLedgersContext } from "./NonZeroBalanceLedgersContext";
import Actions from "./Actions";
import { UserContext } from "../../contexts/UserContext";
import { StringParam, useQueryParam } from "use-query-params";
import { InternalUser } from "../../types/app";
import axios from "axios";
import { DashboardReportType } from "../../checks/types";
import NotesModal from "../../checks/checkNotes/NotesModal";
import { NonZeroBalanceLedger } from "./types";
import { NotesColumn } from "../NotesColumn";
import Iconify from "../../../minimals/components/iconify";
import { exportTableToCsv } from "../../taskUtils";
import ClosingChecklistEscrow from "../../taskManagement/ClosingChecklistEscrow";
import { saveEscrowLedgerAssignTo } from "./saveAssignTo";
import { saveNonZeroBalanceLedgerNote } from "./saveNote";
import dayjs, { Dayjs } from "dayjs";
import {EscrowLedger} from "../escrowLedgers/types";

const today = dayjs();
const defaultEndDate = null;
const defaultStartDate = null;

export default function NonZeroBalanceLedgers() {
  const { nonZeroBalanceLedgers, loading, fetchNonZeroBalanceLedgers, assignedToMe, setAssignedToMe, setNonZeroBalanceLedgers,tableRef, } =
    useContext(NonZeroBalanceLedgersContext);
  const { user } = useContext(UserContext);
  const [assignedToId] = useQueryParam("assignedtoid", StringParam);
  const [assignedToUser, setAssignedToUser] = useState<InternalUser | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<NonZeroBalanceLedger | null>(null);
  const [enableOrdering, setEnableOrdering] = useState(false);


  const isUnassignedOnly = Number(assignedToId) == -1;

  const getAndSetAssignedToUser = async () => {
    if (!assignedToId || isUnassignedOnly) {
      return;
    }
    const { data } = await axios.get<InternalUser>(
        `/api/clientphonebook/internalusers/getInternalUserById?id=${assignedToId}`
    );
    setAssignedToUser(data);
  };
  
  const toggleColumnOrdering = () => {
    setEnableOrdering(!enableOrdering);
  };

  const filteredNonZeroBalanceLedgers = useMemo(() => {
    let ledgers = nonZeroBalanceLedgers
    if (assignedToId) {
      return ledgers.filter((o) => assignedToUser?.fullName?.toLowerCase() === o.assignedTo?.toLowerCase());
    } else if (assignedToMe) {
      return ledgers.filter((o) => user?.email?.toLowerCase() === o.assignedToEmail?.toLowerCase());
    } else if (isUnassignedOnly) {
      return ledgers.filter((o) => !o.assignedTo);
    } else return ledgers;
  }, [NonZeroBalanceLedgers, assignedToMe, isUnassignedOnly]);

  useEffect(() => {
    getAndSetAssignedToUser();
  }, [assignedToId]);

  const onRowsDeselected = () => {
    if (tableRef.current) {
      tableRef.current.toggleAllRowsSelected(false);
    }
  };
  const getTotal = (table: MRT_TableInstance<EscrowLedger>) => {
    return loading ? 0 : table.getExpandedRowModel().rows.reduce((sum, row) => sum + (row.original?.amount || 0), 0);
  };
  
  const columnsWithNotes = NotesColumn<NonZeroBalanceLedger>(columns, setSelectedOrder);

  return (
    <>
      <Helmet>
        <title>Non Zero Balance Ledgers | TitleQ</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h4" marginBottom={2}>
            Non Zero Balance Ledgers
          </Typography>
         
        </Stack>
        <MaterialReactTable
          columns={columnsWithNotes}
          data={filteredNonZeroBalanceLedgers}
          enableGlobalFilter
          enableFacetedValues
          enableColumnOrdering={enableOrdering}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
            showColumnFilters: true,
            columnVisibility: {
              dueDate: false,
              for: false
            },
            columnFilters: [
              {
                id: "dueDate",
                value: [defaultStartDate, defaultEndDate]
              }
            ]
          }}
          state={{
            showSkeletons: loading
          }}
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => {
            // Apply zebra striping styles to alternate rows
            return {
              style: row.index % 2 === 0 ? { backgroundColor: "#f4f4f4" } : {} // Change the background color as needed
            };
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <>
                <Actions
                    assignedToMe={assignedToMe}
                    setAssignedToMe={setAssignedToMe}
                    count={loading ? null : table.getRowCount()}
                    total={getTotal(table)}
                    hideTotal={false}
                    hideAssignedToMeOptions={!!assignedToId}
                    hideReasignAction={true}
                    table={table}
                    fetchTableData={() => fetchNonZeroBalanceLedgers(true)}
                    reportType={DashboardReportType.EscrowOverdue}
                    onRowsDeselected={onRowsDeselected}
                />
                <Box sx={{ flexGrow: 1, position: "relative" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                   
                    <Box sx={{ position: "absolute", right: 0 }}>
                      <Tooltip title="Column ordering">
                        <IconButton onClick={toggleColumnOrdering}>
                          <Iconify icon="mdi:reorder-vertical" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Export to csv">
                        <IconButton onClick={() => exportTableToCsv(table, "export.csv")}>
                          <Iconify icon="eva:download-fill" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
              </>
            );
          }}
        />
      </Container>
      {!!selectedOrder && (
        <NotesModal
          isOpen
          nameBeforeUpdate={selectedOrder?.assignedTo || ""}
          emailBeforeUpdate={selectedOrder?.assignedToEmail || ""}
          entity={selectedOrder}
          setEntity={setSelectedOrder}
          setEntityTable={setNonZeroBalanceLedgers}
          reportType={DashboardReportType.EscrowOverdue}
          saveEscrowLedgerAssignTo={async (userName) => {
            await saveEscrowLedgerAssignTo(selectedOrder.name, userName);
          }}
          saveEscrowLedgerNote={async (text) => {
            await saveNonZeroBalanceLedgerNote(selectedOrder.name, selectedOrder?.amount, text, user?.name as string);
          }}
        />
      )}
    </>
  );
}
