import { Grid, Stack, TextField, Autocomplete, Card, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { PropertyInfo } from "../orderEntry/types/Property";
import AddressSearch from "./AddressSearch";
import { County, PropertyErrors, State } from "./types";

interface Props {
  propertyInfo: PropertyInfo;
  setPropertyInfo: (value: any) => void;
  county: County;
  setCounty: (e: any) => void;
  stateValue: State;
  setStateValue: (e: any) => void;
  stateError: boolean;
  cityError: boolean;
  addressError: boolean;
  sblError: boolean;
}

export default function NewPropertyInfo(props: Props) {
  const [states, setStates] = useState<State[]>([]);
  const [counties, setCounties] = useState<County[]>([]);

  const unitInputRef = useRef<HTMLInputElement | null>(null);

  const getStates = async () => {
    const { data } = await axios.get("/api/blacklist/getstates");
    setStates(data);
  };
  const handleStateChange = (selectedState: any) => {
    if (selectedState) {
      setCounties(selectedState.counties || []);
      props.setStateValue(selectedState)
      props.setCounty({})
    } else {
      setCounties([]);
      props.setStateValue({});
      props.setCounty({})
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <Card sx={{ p: 3, maxWidth: 760, marginTop: 4 }}>
       <Typography variant="h5">Address</Typography>
      <Stack direction="row" justifyContent="space-between" marginTop="40px" spacing={2}>
        <Grid item md={10} xs={12}>
          <AddressSearch
            propertyInfo={props.propertyInfo}
            setPropertyInfo={props.setPropertyInfo}
            states={states}
            counties={counties}
            setCounties={setCounties}
            setStateValue={props.setStateValue}
            stateValue={props.stateValue}
            error={props.addressError}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <TextField
            label="Unit/Suite/Apt #"
            value={props.propertyInfo?.aptNo || ""}
            onChange={(e) => {
              props.setPropertyInfo({ ...props.propertyInfo, aptNo: e.target.value });
            }}
            inputRef={unitInputRef}
          />
        </Grid>
      </Stack>
      <Stack direction="row" justifyContent="space-between" marginTop="24px" spacing={3}>
        <Grid item md={10} xs={12}>
          <TextField
            label="City"
            value={props.propertyInfo?.city || ""}
            onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, city: e.target.value })}
            fullWidth
            error={props.cityError}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={states || []}
            renderInput={(params) => <TextField {...params} label="State" error={props.stateError} required/>}
            getOptionLabel={(option) => option.name || props.stateValue.name || ""}
            onChange={(e, value) => handleStateChange(value)}
            value={props.stateValue || null}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            label="Zip Code"
            value={props.propertyInfo?.zipCode || ""}
            onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, zipCode: e.target.value })}
          />
        </Grid>
      </Stack>
      <Stack direction="row" marginTop="30px" justifyContent="space-between" spacing={4}>
        <TextField
          label="Section/Borough"
          value={props.propertyInfo?.section || props.propertyInfo?.borough}
          onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, section: e.target.value })}
          //error={props.sblError}
        />
        <TextField
          label="Block"
          value={props.propertyInfo?.block}
          onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, block: e.target.value })}
          error={props.sblError}
        />
        <TextField
          label="Lot"
          value={props.propertyInfo?.lots}
          onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, lots: [e.target.value] })}
          error={props.sblError}
        />
        <Autocomplete
          options={counties || []}
          renderInput={(params) => <TextField {...params} label="County" fullWidth />}
          getOptionLabel={(option) => option.name || props.county.name || ""}
          onChange={(e, val) => props.setCounty( val )}
          value={props.county || null}
          fullWidth
        />
      </Stack>
      <Stack direction="row" marginTop="30px" justifyContent="space-between" spacing={2}>
        <TextField
          label="Parcel ID"
          value={props.propertyInfo?.parcelIds}
          onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, parcelIds: [e.target.value] })}
          fullWidth
        />
        <TextField
          label="Subdivision/Condo/PUD/Addition"
          value={props.propertyInfo?.condoNumber}
          onChange={(e) => props.setPropertyInfo({ ...props.propertyInfo, condoNumber: e.target.value })}
          fullWidth
        />
      </Stack>
    </Card>
  );
}
