import { useContext } from "react";
import axios from "axios";
import { prependUserToSelectNote } from "../../utils/prependUserToSelectNote";
import {fCurrency} from "../../utils/formatNumber";

export const saveNonZeroBalanceLedgerNote = async (ledgerName: string, ledgerBalance: number | undefined, note: string, user: string) => {
  let noteWithPrependedUser = prependUserToSelectNote(note, user);
  if(ledgerBalance) {
    noteWithPrependedUser += `\r\n Ledger balance when this note was added was ${fCurrency(ledgerBalance)}`;
  }

  await axios.post("/proxy/api/ledgers/addLedgerNote", {
    ledgerName,
    note: noteWithPrependedUser
  });
};
