import { createContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Policy, PoliciesData, DashboardNote } from "./types";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { MRT_TableInstance } from "material-react-table";
//import { policiesToSendSort } from "./PoliciesToSendSort";

// ----------------------------------------------------------------------

const PoliciesToSendContext = createContext<PoliciesData>({} as PoliciesData);

function PoliciesToSendProvider({ children }: { children: JSX.Element }) {
  const today = dayjs();
  const defaultEndDate = today.subtract(10, "days");
  const defaultStartDate = dayjs("7/4/2023");
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([defaultStartDate, defaultEndDate]);
  const [loading, setLoading] = useState(true);
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(true);

  const tableRef = useRef<MRT_TableInstance<Policy> | null>(null);
  const resetPaginationFunc = () => {
    if (tableRef.current) {
      tableRef.current.resetPageIndex();
    }
  };

  const fetchPolicies = async ({ useLoader = true, resetPagination = true } = {}): Promise<void> => {
    if (useLoader) {
      setLoading(true);
    }
 
    const { data } = await axios.get<Policy[]>(`/api/OutstandingChecks/GetPoliciesToSend`);
    const filterData: any = data
    .map((item) => {
      const rows = [];
      if (item.loanPolicyReadyToBeSent && !item.loanPolicyAlreadySent) {
        rows.push({
          orderNumber: item.orderNumber,
          closer: item.closer,
          policyType: "Loan",
          selectId: item.selectId,
          notes: item.notes,
          closerUser: item.closerUser,
          assignedToUser: item.assignedToUser
        });
      }
      if (item.ownersPolicyReadyToBeSent && !item.ownersPolicyAlreadySent) {
        rows.push({
          orderNumber: item.orderNumber,
          closer: item.closer,
          policyType: "Owners",
          selectId: item.selectId,
          notes: item.notes,
          closerUser: item.closerUser,
          assignedToUser: item.assignedToUser
        });
      }
      return rows.length > 0 ? rows : null;
    })
    .flat()
    .filter(Boolean);
    setPolicies(filterData);

    if (useLoader) {
      setLoading(false);
    }
    if (resetPagination) {
      resetPaginationFunc();
    }
  };
  useEffect(() => {
    fetchPolicies({ useLoader: true, resetPagination: true });
  }, [dateRange]);

  return (
    <PoliciesToSendContext.Provider
      value={{
        setDateRange,
        dateRange,
        loading,
        policies,
        setPolicies,
        assignedToMe,
        setAssignedToMe,
        fetchPolicies,
        tableRef
      }}
    >
      {children}
    </PoliciesToSendContext.Provider>
  );
}

export { PoliciesToSendProvider, PoliciesToSendContext };
