import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Stack,
  Typography
} from "@mui/material";
import { useQueryParam, StringParam } from "use-query-params";
import DebouncedOrderSearch from "../../../components/DebouncedOrderSearch";
import axios from "axios";
import { Policy, PolicyType } from "./types";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateData: () => void;
}

const NewPolicyModal = ({ isOpen, setIsOpen, updateData }: Props) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayErrorToast, setIsDisplayErrorToast] = useState(false);
//   const [orderNumberUrlParam, setOrderNumberUrlParam] = useQueryParam("ordernumber", StringParam);
  const [policiesOfOrder, setPoliciesOfOrder] = useState<Policy[]>([]);
  
 
  const handleCloseModal = () => {
    setIsLoading(false);
    setIsOpen(false);
  };

  const handleCloseToast = () => setIsDisplayErrorToast(false);

  const getPoliciesOfOrder = async (value: any) => {
    setIsLoading(true);
    const { data } = await axios.get<Policy[]>(`proxy/api/orders/getpolicies?orderNumber=${value}`);
    setPoliciesOfOrder(data);
    setIsLoading(false);
  };

  const onSave = async () => {
    const result = policiesOfOrder?.map(policy => ({
        ...policy,    
        id: 0,                  
        selectId: policy.id, 
        orderNumber: selectedValue,
        type: policy.type === 'LoanPolicy' ? PolicyType.Loan : PolicyType.Owners,   
      }));
    await axios.post("/api/outstandingChecks/AddPolicies", result);
    updateData();
    handleCloseModal();
    
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleCloseModal} fullWidth>
        <DialogTitle>Add Policy</DialogTitle>
        <DialogContent sx={{ marginTop: "35px" }}>
          <DebouncedOrderSearch
            value={selectedValue}
            textboxMargin="dense"
            onSelect={async (e: React.SyntheticEvent<Element, Event>, option: string | null, reason: string) => {
              setSelectedValue(option || "");
              if (option) {
                getPoliciesOfOrder(option);
              }
            }}
          />
          {/* <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={isDisplayErrorToast}
            autoHideDuration={5000}
            onClose={handleCloseToast}
          >
            <Alert onClose={handleCloseToast} severity="error" sx={{ width: "100%" }}>
              There is an issue with this order and it cannot be edited
            </Alert>
          </Snackbar> */}
          {selectedValue &&
            (isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box>
            ) : (
              <>
                <Typography marginBottom={2} marginTop={2}>The policies of order # {selectedValue} will be added:</Typography>
                <Stack direction="row" spacing={2} justifyContent="space-between" marginBottom={2}>
                  <Typography fontWeight={"bold"}>Policy Type</Typography>
                  <Typography fontWeight={"bold"}>Policy Number</Typography>
                </Stack>
                {policiesOfOrder?.map((policy: Policy, index) => {
                  const policyType = policy.type
                    ? `${policy.type} ${policy.position || ""}`.replace(/policy/i, "").trim()
                    : "Loan";
                  return (
                    <Stack key={index} direction="row" spacing={2} marginTop={1} justifyContent="space-between">
                      <Typography>{policyType}</Typography>
                      <Typography>{policy.policyNumber}</Typography>
                    </Stack>
                  );
                })}
              </>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSave}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewPolicyModal;
