import { Box, Button, Container, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MaterialReactTable } from "material-react-table";
// import {csv} from "papaparse";
import { useRef, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";
import { InternalUser } from "../../../types/app";
import { NotesColumn } from "../../NotesColumn";
import { exportTableToCsv } from "../../../taskUtils";
import Iconify from "../../../../minimals/components/iconify";
import NotesModal from "../../../checks/checkNotes/NotesModal";
import { PolicyIssuesContext } from "./PolicyIssuesContext";
import { DashboardReportType, Policy } from "./types";
import { getColumns } from "./columns";
import PolicyIssuesActions from "./PolicyIssuesActions";
import UploadPolicyModal from "./UploadPolicyModal";
import NewPolicyModal from "./NewPolicyModal";

export default function PolicyIssuesDashboard() {
  const {
    policies,
    loading,
    setDateRange,
    dateRange,
    assignedToMe,
    setAssignedToMe,
    fetchPolicies,
    setPolicies,
    tableRef
  } = useContext(PolicyIssuesContext);
  const { user } = useContext(UserContext);
  const [managerDepartments, setManagerDepartments] = useState<string[]>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const assignedToId = queryParams.get("assignedtoid");
  const isUnassignedOnly = Number(assignedToId) == -1;
  const [assignedToUser, setAssignedToUser] = useState<InternalUser | null>(null);
  const [selectedPolicy, setSelectedPolicy] = useState<Policy | null>(null);
  const [selectedUploadPolicy, setSelectedUploadPolicy] = useState<Policy | null>(null);
  const [enableOrdering, setEnableOrdering] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openNewPolicyModal, setOpenNewPolicyModal] = useState(false);

  const getAndSetManagerDepartmentsByEmail = async (emailAddress: string) => {
    const { data } = await axios.get<string[]>(
      `/api/outstandingchecks/getManagerDepartmentsByEmail?emailAddress=${emailAddress}`
    );
    setManagerDepartments(data);
  };

  const getAndSetAssignedToUser = async () => {
    if (!assignedToId || isUnassignedOnly) {
      return;
    }
    const { data } = await axios.get<InternalUser>(
      `/api/clientphonebook/internalusers/getInternalUserById?id=${assignedToId}`
    );
    setAssignedToUser(data);
  };

  const toggleColumnOrdering = () => {
    setEnableOrdering(!enableOrdering);
  };
  const handleOpenModal = (selectedPolicy: Policy) => {
    setSelectedUploadPolicy(selectedPolicy);
    setOpenUpload(true);
  };
  const handleCloseModal = () => {
    setOpenUpload(false);
    setSelectedUploadPolicy(null);
  };
  const filteredPolicies = useMemo(() => {
    if (isUnassignedOnly) {
      return policies.filter((p: Policy) => !p.assignedToUser);
    }
    if (!assignedToMe) return policies;

    if (assignedToId) {
      return policies.filter((p: Policy) => assignedToId == String(p.assignedToUser?.id || ""));
    }
    return policies.filter((p) => user?.id && p.assignedToUser?.id === user.id);
  }, [assignedToMe, policies, assignedToId, user?.id, managerDepartments]);

  useEffect(() => {
    getAndSetAssignedToUser();
  }, [assignedToId]);

  useEffect(() => {
    if (user?.email) {
      getAndSetManagerDepartmentsByEmail(user.email);
    }
  }, [user]);

  const onRowsDeselected = () => {
    if (tableRef.current) {
      tableRef.current.toggleAllRowsSelected(false);
    }
  };

  const onChangeStatus = (selectedPolicy: Policy) => {
    
  }
  const onSaveUpload =() => {

  }
  const columns = getColumns(handleOpenModal);

  const columnsWithNotes = NotesColumn<Policy>(columns, setSelectedPolicy);
  return (
    <>
      <Helmet>
        <title> Policy Issues | TitleQ</title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" marginBottom={2}>
            Policy Issues
            {isUnassignedOnly && " - Unassigned"}
            {assignedToUser && ` - Assigned to ${assignedToUser.firstName} ${assignedToUser.lastName}`}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setOpenNewPolicyModal(true)}
            size="medium"
          >
            Add Policy
          </Button>
        </Stack>
        <MaterialReactTable
          columns={columnsWithNotes}
          data={filteredPolicies}
          enableGlobalFilter
          enableFacetedValues
          enableColumnOrdering={enableOrdering}
          enableRowSelection
          enableRowActions
          autoResetPageIndex={false}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
            columnVisibility: {
              transactionType: false,
              voidedOn: false,
              status: false,
              extendedMemo: false
            }
          }}
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              style: row.index % 2 === 0 ? { backgroundColor: "#f4f4f4" } : {}
            };
          }}
          state={{ showSkeletons: loading }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Box sx={{ flexGrow: 1, position: "relative" }}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <PolicyIssuesActions
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    assignedToMe={assignedToMe}
                    setAssignedToMe={setAssignedToMe}
                    table={table}
                    hideAssignedToMeOptions={!!assignedToId}
                    onRowsDeselected={onRowsDeselected}
                  />
                  <Box sx={{ position: "absolute", right: 0 }}>
                    <Tooltip title="Column ordering">
                      <IconButton onClick={toggleColumnOrdering}>
                        <Iconify icon="mdi:reorder-vertical" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Export to csv">
                      <IconButton onClick={() => exportTableToCsv(table, "export.csv")}>
                        <Iconify icon="eva:download-fill" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
              </Box>
            );
          }}
        />
      </Container>
      {!!selectedPolicy && (
        <NotesModal
          isOpen
          nameBeforeUpdate={selectedPolicy?.assignedToUser?.fullName || ""}
          emailBeforeUpdate={selectedPolicy?.assignedToUser?.email || ""}
          entity={selectedPolicy}
          setEntity={setSelectedPolicy}
          setEntityTable={setPolicies}
          refetchData={() => fetchPolicies({ useLoader: false, resetPagination: false })}
          reportType={DashboardReportType.PolicyIssues}
        />
      )}
      {openUpload && (
        <UploadPolicyModal
        selectedPolicy={selectedPolicy}
        setSelectedPolicy={setSelectedPolicy}
        updatePolicies={fetchPolicies}
        // orderNumber={selectedPolicy?.orderNumber}

        open={openUpload}
        handleClose={handleCloseModal}
        // onSave={onSaveUpload}
        />
      )} 
      {openNewPolicyModal && (
       <NewPolicyModal isOpen={openNewPolicyModal} setIsOpen={setOpenNewPolicyModal} updateData={fetchPolicies}/>
      )}
    </>
  );
}
