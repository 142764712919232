import { createContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { RecordingLedger, RecordingLedgerData } from "./types";
import { MRT_TableInstance } from "material-react-table";
import { processLedgerData } from "./processLedgerData";

// ----------------------------------------------------------------------

const RecordingLedgersContext = createContext<RecordingLedgerData>({} as RecordingLedgerData);

function RecordingLedgersContextProvider({ children }: { children: JSX.Element }) {
  const [loading, setLoading] = useState(true);
  const [recordingLedgers, setRecordingLedgers] = useState<RecordingLedger[]>([]);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(false);
  const [checklist23Completed, setChecklist23Completed] = useState(false);
  const [recordClosingDocsReceived, setRecordClosingDocsReceived] = useState(false);

  const tableRef = useRef<MRT_TableInstance<any> | null>(null);
  const getRecordingLedgers = async (useLoader: boolean = true) => {
    if (useLoader) {
      setLoading(true);
    }
    const { data } = await axios.get<RecordingLedger[]>(`/api/ledgers/getRecordingLedgers`);
    const ledgers = processLedgerData(data);
    setRecordingLedgers(ledgers);
    if (useLoader) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecordingLedgers(true);
  }, []);

  return (
    <RecordingLedgersContext.Provider
      value={{
        loading,
        recordingLedgers,
        setRecordingLedgers,
        assignedToMe,
        setAssignedToMe,
        getRecordingLedgers,
        tableRef,
        checklist23Completed,
        setChecklist23Completed,
        recordClosingDocsReceived,
        setRecordClosingDocsReceived
      }}
    >
      {children}
    </RecordingLedgersContext.Provider>
  );
}

export { RecordingLedgersContextProvider, RecordingLedgersContext };
