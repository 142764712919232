import { createContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { NonZeroBalanceLedger, NonZeroBalanceLedgersData } from "./types";
import { MRT_TableInstance } from "material-react-table";
import { processLedgerData } from "./processLedgerData";

// ----------------------------------------------------------------------

const NonZeroBalanceLedgersContext = createContext<NonZeroBalanceLedgersData>({} as NonZeroBalanceLedgersData);

function NonZeroBalanceLedgersContextProvider({ children }: { children: JSX.Element }) {
  const [loading, setLoading] = useState(true);
  const [nonZeroBalanceLedgers, setNonZeroBalanceLedgers] = useState<NonZeroBalanceLedger[]>([]);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(true);

  const tableRef = useRef<MRT_TableInstance<NonZeroBalanceLedger> | null>(null);
  const fetchNonZeroBalanceLedgers = async (useLoader: boolean = true) => {
    if (useLoader) {
      setLoading(true);
    }
    const { data } = await axios.get<NonZeroBalanceLedger[]>(`/api/ledgers/GetNonZeroBalanceLedgers`);
    const ledgers = processLedgerData(data);
    setNonZeroBalanceLedgers(ledgers);
    if (useLoader) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNonZeroBalanceLedgers(true);
  }, []);

  return (
    <NonZeroBalanceLedgersContext.Provider
      value={{
        loading,
        nonZeroBalanceLedgers,
        setNonZeroBalanceLedgers,
        assignedToMe,
        setAssignedToMe,
        fetchNonZeroBalanceLedgers,
        tableRef
      }}
    >
      {children}
    </NonZeroBalanceLedgersContext.Provider>
  );
}

export { NonZeroBalanceLedgersContextProvider, NonZeroBalanceLedgersContext };
