import axios from "axios";
import InternalUserAutocomplete from "../../shared/InternalUserAutocomplete";
import { InternalUser } from "../../types/app";
import { ClientOrder } from "../types/ClientOrder";
import { useState } from "react";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function SalesPersonAutocomplete({
  clientOrder,
  update
}: {
  clientOrder: ClientOrder;
  update: (clientOrder: ClientOrder, salesReps: InternalUser[]) => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<InternalUser[]>([]);
  const limit = 3;

  const handleSalesRepsChange = async () => {
    setLoading(true);
    const salesRepIds = users.map((u) => u.salesRepId);
    await axios.post("/proxy/api/orders/setsalesreps", {
      orderNumber: clientOrder.number,
      salesRepIds
    });
    update(clientOrder, users);
    setLoading(false);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center" maxWidth="100%">
      <Box width="100%">
        <InternalUserAutocomplete
          value={users}
          onChange={(value) => {
            // Limit selected option to max 3
            (value as InternalUser[]).length <= limit ? setUsers(value as InternalUser[]) : null;
          }}
          size="medium"
          label="Select Sales Person"
          multiple
          limitTags={2}
          limitToSalesReps
          getOptionDisabled={(option: InternalUser) => users.length === limit}
        />
      </Box>
      {users.length > 0 && (
        <Box width="100px">
          <LoadingButton
            fullWidth
            variant="contained"
            size="medium"
            loading={loading}
            onClick={handleSalesRepsChange}
            loadingIndicator="Saving…"
          >
            Save
          </LoadingButton>
        </Box>
      )}
    </Stack>
  );
}
