import React, { useState } from 'react';
import { Box, Typography, IconButton, Chip, Stack } from '@mui/material';
import {
  Description as FileIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  CalendarToday as CalendarIcon,
  ListAlt as TagIcon,
} from '@mui/icons-material';
import BulletinUploadModal from './BulletinUploadModal';
import { UploadInfo } from './types';
import { fMonthDayYearSlashNotation } from '../utils/formatTime';

interface Props{
     index: number;
     upload: UploadInfo;
     onSave: (value: UploadInfo, index?: number) => void;
     handleRemoveUpload: (index:number) => void;
}

const BulletinUpload = (props: Props) => {
  const [openEdit, setOpenEdit] = useState(false);

  return (
    <Box
      sx={{
        borderRadius: '8px',
        padding: '8px 16px',
        border: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
    >
      <Stack direction={'row'} alignItems='center'>
        <FileIcon sx={{ color: '#666' }} />
        <Typography sx={{ flexGrow: 1, fontWeight: 500, marginLeft: 1 }}>
          {// @ts-ignore
           props.upload?.file.path}
        </Typography>
        <IconButton aria-label='delete' color='inherit' size='small' onClick={() => props.handleRemoveUpload(props.index)}>
          <DeleteIcon sx={{ fontSize: 16 }} />
        </IconButton>
        <IconButton
          aria-label='edit'
          color='inherit'
          onClick={() => setOpenEdit(!openEdit)}
        >
          <EditIcon sx={{ fontSize: 16 }} />
        </IconButton>
      </Stack>
      <Stack direction={'row'} spacing={3} marginTop={2}>
        <Chip
          icon={<TagIcon />}
          // @ts-ignore
          label={props.upload.underwriter.name || ''}
          sx={{
            color: '#fff',
            backgroundColor: '#1817A8',
            fontWeight: 500,
            borderRadius: '8px',
            width: 140,
            '& .MuiChip-icon': { color: '#fff' },
          }}
        />
        <Chip
          icon={<CalendarIcon />}
          label={fMonthDayYearSlashNotation(props.upload?.date)}
          sx={{
            color: '#fff',
            backgroundColor: '#1817A8',
            fontWeight: 500,
            borderRadius: '8px',
            width: 125,
            '& .MuiChip-icon': { color: '#fff' },
          }}
        />
        <Chip
          label={ `${props.upload?.number && '#'}${props.upload?.number}`}
          sx={{
            color: '#fff',
            backgroundColor: '#1817A8',
            borderRadius: '8px',
            width: 80,
            fontWeight: 500,
          }}
        />
      </Stack>
      {openEdit && <BulletinUploadModal 
         open={openEdit}
         onClose={() =>  setOpenEdit(false)}
         onSave={props.onSave}
         index={props.index}
         editUpload={props.upload}
      />}
    </Box>
  );
};

export default BulletinUpload;
