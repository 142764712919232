import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Stack,
  Autocomplete,
  IconButton,
  InputAdornment,
} from '@mui/material';
import axios from 'axios';
import {MaterialReactTable} from 'material-react-table';
import {useEffect, useState} from 'react';
import {LedgerTransfer, PendingCheck, TransactionHistory} from './types';
import {CloseIcon} from '../../../minimals/theme/overrides/CustomIcons';
import produce from 'immer';
import {NumberFormatCustom} from '../NumberFormat';

const PendingCheckModal = ({
                             open,
                             closeModal,
                             orderNumber,
                             handleRefreshList,
                             handleRefreshBalance,
                           }: {
  open: boolean;
  closeModal: () => void;
  orderNumber: any;
  handleRefreshList: () => void;
  handleRefreshBalance: () => void;
}) => {
  const [pendingCheck, setPendingCheck] = useState<PendingCheck>({
    orderNumber: orderNumber,
    payeeContact: {
      id: '',
      name: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    },
    payeeContactNameOnly: '',
    memo: '',
    amount: '',
  });
  const [orderContacts, setOrderContacts] = useState([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const setPendingCheckInfo = (name: keyof PendingCheck, value: any) => {
    
   const newInfo = produce(pendingCheck, draft => {
     draft[name] = value;
   })
    setPendingCheck(newInfo);
  };

 
  
  const handleSubmit = async () => {
    setSubmitting(true);
    await axios.post('/proxy/api/ledgers/CreatePendingCheck', pendingCheck);
    closeModal();
    handleRefreshBalance();
    setSubmitting(false);
    handleRefreshList();
  };

  const getOrderContacts = async () => {
    const {data} = await axios.get(
        `/proxy/api/orders/GetOrderContacts?orderNumber=${orderNumber}`
    );
    setOrderContacts(data);
  };

  useEffect(() => {
    getOrderContacts();
  }, []);

  return (
      <Dialog open={open} onClose={closeModal} fullWidth maxWidth='sm'>
        <DialogTitle>
          <Typography variant='h4'>New Pending Check</Typography>
        </DialogTitle>
        <DialogContent sx={{paddingBottom: 3}}>
          <IconButton
              onClick={closeModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
          >
            <CloseIcon/>
          </IconButton>
          <Box paddingTop={1}>
            <Stack direction='row' spacing={2} justifyContent='flex-end'>
              <TextField
                  label='Amount'
                  size='small'
                  value={pendingCheck?.amount}
                  onChange={(e) => {
                    setPendingCheckInfo('amount', e.target.value);
                  }}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
              />
            </Stack>
            <Stack direction='column' spacing={2} marginTop={2}>
              <Autocomplete
                  renderInput={(params) => (
                      <TextField
                          {...params}
                          size='small'
                          fullWidth
                          label='Pay to the order of'
                      />
                  )}
                  options={orderContacts}
                  onInputChange={(_, inputValue, reason) => {
                    if (reason === 'input') {
                      setPendingCheckInfo('payeeContactNameOnly', inputValue)
                    }
                  }
                  }
                  getOptionLabel={(option: any) => `${option.name} (${option.type})`}
                  onChange={(_, value) => {
                    const copy = {...pendingCheck}
                    copy.payeeContact = value;
                    copy.payeeContactNameOnly = '';
                    setPendingCheck(copy);
                  }
                  }
                  freeSolo
              />
              <TextField
                  label='Memo'
                  size='small'
                  fullWidth
                  value={pendingCheck?.memo}
                  onChange={(e) => {
                    setPendingCheckInfo('memo', e.target.value);
                  }}
              />
            </Stack>
          </Box>
          <Typography marginTop={3} marginBottom={3}>
            Open Select to print pending checks
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={closeModal} color='inherit'>
            Cancel
          </Button>
          <Button
              variant='contained'
              onClick={handleSubmit}
              disabled={submitting}
          >
            {submitting ? 'Creating....' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default PendingCheckModal;
