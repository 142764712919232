import { Alert, Button, Container, Grid, Stack, Typography } from "@mui/material";
import Page from "../../minimals/components/page";
import { useSettingsContext } from "../../minimals/components/settings";
import NewPropertyInfo from "./NewPropertyInfo";
import { useState } from "react";
import { useNavigate } from "react-router";
import { BulletinInformation, County, PropertyErrors, State, UploadInfo } from "./types";
import produce from "immer";
import BulletinInfo from "./BulletinInfo";
import axios from "axios";
import { stripDataUriPrefix } from "../utils/stripDataUriPrefix";
import { toBase64 } from "../utils/helpers";
import { PropertyInfo } from "../orderEntry/types/Property";

export default function AddBlacklistProperty() {
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const baseBulletin = {
    state: { id: 0, name: "", abbreviation: "", counties: [] },
    county: {
      id: 0,
      stateId: 0,
      name: "",
      state: {
        id: 0,
        name: "",
        abbreviation: "",
        counties: []
      }
    },
    note: ""
  };

  const [property, setProperty] = useState<PropertyInfo>({} as PropertyInfo);
  const [stateValue, setStateValue] = useState<State>({} as State);
  const [county, setCounty] = useState<County>({} as County);
  const [bulletinInfo, setBulletinInfo] = useState<BulletinInformation>(baseBulletin);
  const [uploads, setUploads] = useState<UploadInfo[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [bulletinStateError, setBulletinStateError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [sblError, setSblError] = useState(false);
  const [bulletinError, setBulletinError] = useState(false);
  const [notes, setNotes] = useState<string>("");

  const addOrEditUpload = (newUpload: UploadInfo, index?: number) => {
    if (index === undefined) {
      setUploads((prevUploads) => [...prevUploads, newUpload]);
      return;
    }
    setUploads((prevUploads) => {
      return prevUploads.map((pu, i) => {
        return i === index ? newUpload : pu;
      });
    });
  };

  const changeBulletinInfo = (updates: Partial<BulletinInformation>) => {
    const newBulletinInfo = produce(bulletinInfo, (draft: any) => {
      for (const [key, value] of Object.entries(updates)) {
        (draft[key] as any) = value;
      }
    });
    setBulletinInfo(newBulletinInfo);
  };
  const handleRemoveUpload = (index: number) => {
    setUploads((prevUploads) => prevUploads.filter((_, i) => i !== index));
  };

  const resetFunction = () => {
    setSubmitting(false);
    setSuccessMessage(true);
    setBulletinInfo(baseBulletin);
    setProperty({} as PropertyInfo);
    setUploads([]);
    setSuccessMessage(false);
    navigate("/blacklistsearch");
  };
  const isAddressIncomplete =
    !property.address &&
    !property.parcelIds?.[0] &&
    !property.block &&
    !property.lots?.[0] &&
    // !property.section &&
    !property.condoNumber;

  const isSblIncomplete =
    (property.block || property?.lots?.[0]) &&
    (!property.block || !property?.lots?.[0]) ||
    property.section && !(property.section && (property.block && property?.lots?.[0]));  

  const onBlurProperty = () => {
    if (bulletinInfo.state.id) {
      setBulletinStateError(false);
    }
  };
  const onSubmit = async () => {
    if (!stateValue?.id) {
      setStateError(true);
    }
    if (!property.city) {
      setCityError(true);
    }
    if (isAddressIncomplete) {
      setAddressError(true);
    }
    if (!bulletinInfo.state.id) {
      setBulletinStateError(true);
    }
    if (isSblIncomplete) {
      setSblError(true);
    }
    if (uploads.length === 0) {
      setBulletinError(true);
    }
    if (
      !stateValue?.id ||
      !property.city ||
      isAddressIncomplete ||
      !bulletinInfo.state.id ||
      isSblIncomplete ||
      uploads.length === 0
    ) {
      return;
    }
    const blacklistProperty = {
      property: {
        address: property?.address,
        city: property?.city,
        section: property?.section,
        block: property?.block,
        unit: property?.aptNo,
        zip: property?.zipCode,
        lot: property?.lots?.[0],
        parcelId: property?.parcelIds?.[0],
        subdivision: property?.condoNumber,
        countyId: county?.id,
        stateId: stateValue?.id,
        latitude: 0,
        longitude: 0,
        geocodeAccuracy: "",
        notes
      },
      countyId: bulletinInfo?.county?.id,
      stateId: bulletinInfo?.state?.id,
      bulletinsWithBase64: await Promise.all(
        uploads.map(async (u) => {
          const base64File = stripDataUriPrefix(await toBase64(u.file));
          return {
            bulletin: {
              number: u.number,
              date: u.date,
              //@ts-ignore
              underwriterId: u.underwriter.id
            },
            base64File
          };
        })
      )
    };
    setSubmitting(true);
    await axios.post("/api/blacklist/addPropertyRecord", blacklistProperty);
    resetFunction();
  };

  const onCancel = () => {
    navigate("/blacklistsearch");
  };

  return (
    <Page title="Blacklist" width="100%">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4" marginBottom={2}>
                Add Property to Blacklist
              </Typography>
            </Stack>
            <NewPropertyInfo
              propertyInfo={property}
              setPropertyInfo={setProperty}
              stateValue={stateValue}
              setStateValue={setStateValue}
              county={county}
              setCounty={setCounty}
              stateError={stateError}
              cityError={cityError}
              addressError={addressError}
              sblError={sblError}
            />
            <BulletinInfo
              bulletinInfo={bulletinInfo}
              setBulletinInfo={changeBulletinInfo}
              uploads={uploads}
              addUpload={addOrEditUpload}
              handleRemoveUpload={handleRemoveUpload}
              bulletinStateError={bulletinStateError}
              notes={notes}
              setNotes={setNotes}
              bulletinError={bulletinError}
              setBulletinError={setBulletinError}
              onBlurProperty={onBlurProperty}
            />
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent="right" spacing={1.5} width={784} marginTop={8}>
          <Button variant="outlined" color="inherit" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSubmit} disabled={submitting}>
            {submitting ? "Saving..." : "Save"}
          </Button>
          {successMessage && (
            <Alert sx={{ marginTop: 2 }} severity="success">
              Successfully Saved
            </Alert>
          )}
        </Stack>
      </Container>
    </Page>
  );
}
