import { createContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { EscrowLedger, EscrowLedgersData } from "./types";
import { MRT_TableInstance } from "material-react-table";
import { processLedgerData } from "./processLedgerData";

// ----------------------------------------------------------------------

const EscrowLedgersContext = createContext<EscrowLedgersData>({} as EscrowLedgersData);

function EscrowLedgersContextProvider({ children }: { children: JSX.Element }) {
  const [loading, setLoading] = useState(true);
  const [escrowLedgers, setEscrowLedgers] = useState<EscrowLedger[]>([]);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(true);

  const tableRef = useRef<MRT_TableInstance<EscrowLedger> | null>(null);
  const fetchEscrowLedgers = async (useLoader: boolean = true) => {
    if (useLoader) {
      setLoading(true);
    }
    const { data } = await axios.get<EscrowLedger[]>(`/api/ledgers/getescrowledgers`);
    const ledgers = processLedgerData(data);
    setEscrowLedgers(ledgers);
    if (useLoader) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEscrowLedgers(true);
  }, []);

  return (
    <EscrowLedgersContext.Provider
      value={{
        loading,
        escrowLedgers,
        setEscrowLedgers,
        assignedToMe,
        setAssignedToMe,
        fetchEscrowLedgers,
        tableRef
      }}
    >
      {children}
    </EscrowLedgersContext.Provider>
  );
}

export { EscrowLedgersContextProvider, EscrowLedgersContext };
