import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { CloseIcon } from "../../../../minimals/theme/overrides/CustomIcons";
import UploadMultiFile from "../../../components/upload/UploadMultiFile";
import OrderAttachments from "../../../shared/OrderAttachments";
import { Attachment } from "../../../shared/types/Attachment";
import { generateFullPaths } from "../../../taskManagement/documents/generateFullPaths";
import { toBase64 } from "../../../utils/helpers";
import { stripDataUriPrefix } from "../../../utils/stripDataUriPrefix";
import { ConcatenatedDocumentTypes, Policy } from "./types";


export default function DocumentUploadModal({
  selectedPolicy,
  setSelectedPolicy,
  updatePolicies,
  open,
  handleClose
}: {
  selectedPolicy: Policy | null;
  setSelectedPolicy: (value: any) => void;
  updatePolicies: () => void;
  open: boolean;
  handleClose: () => void;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [concatenatedDocumentTypes, setConcatenatedDocumentTypes] = useState<ConcatenatedDocumentTypes[]>([]);
  const [selectedConcatenatedDocumentType, setSelectedConcatenatedDocumentType] =
    useState<ConcatenatedDocumentTypes | null>(null);
  const [additionalDescription, setAdditionalDescription] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [selectedAttachments, setSelectedAttachments] = useState<Attachment[]>([]);
  const [validationError, setValidationError] = useState(false);


  const getAndSetDocumentTypes = async () => {
    const { data } = await axios.get(`/api/taskmanagement/documents/getcategories?ordernumber=${selectedPolicy?.orderNumber}`);

    const concatenatedDocumentTypes = generateFullPaths(data);
    setConcatenatedDocumentTypes(concatenatedDocumentTypes);
  };

  const handleFileDrop = useCallback(
    (acceptedFile: any) => {
      const file = Object.assign(acceptedFile[0], {
        preview: URL.createObjectURL(acceptedFile[0])
      });
      setFile(file);
    },
    [setFile]
  );

  const save = async () => {
    if(selectedPolicy && (selectedPolicy?.policyNumber.length < 6 || selectedPolicy?.policyNumber === 'PRO FORMA'))
    {
      setValidationError(true);
      return;
    }
    setSubmitting(true);
    if (selectedConcatenatedDocumentType && file) {
      await saveNewAttachment();
    }
    if (selectedAttachments.length > 0) {
      await saveMomentumAttachment();
    }
    setSubmitting(false);
    updatePolicies();
    handleClose();
  };

  const saveNewAttachment = async () => {
    await axios.post("/api/taskmanagement/documents/upload", {
      taskId: selectedPolicy?.id,
      documentBase64: stripDataUriPrefix(await toBase64(file as File)),
      fileName: file?.name,
      subcategoryId: selectedConcatenatedDocumentType?.subcategoryId,
      description: selectedConcatenatedDocumentType?.description,
      descriptionAdditional: additionalDescription
    });
  };

  const saveMomentumAttachment = async () => {
    const momentumDocumentIds = selectedAttachments.map((sa) => sa.id);
    await axios.post("/api/taskmanagement/documents/addMomentumDocumentsToTask", {
      taskId: selectedPolicy?.id,
      momentumDocumentIds
    });
  };

  useEffect(() => {
    getAndSetDocumentTypes();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Stack direction="column">
          <Typography variant="subtitle1">Upload Email</Typography>
        </Stack>
      </DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <Stack direction="row" spacing={2} paddingTop={1}>
          <Card sx={{ width: "100%" }}>
            <CardContent>
              <Stack direction="column" justifyContent="space-between" spacing={2}>
                <Autocomplete
                  fullWidth
                  onChange={(e, value) => setSelectedConcatenatedDocumentType(value)}
                  options={concatenatedDocumentTypes}
                  getOptionLabel={(option) => option.fullPath || ""}
                  renderInput={(params) => (
                    <TextField {...params} label="Document Type" placeholder="Start typing..." />
                  )}
                />
                <TextField label="Additional Description" onChange={(e) => setAdditionalDescription(e.target.value)} />
                <UploadMultiFile
                  files={file ? [file] : []}
                  onDrop={handleFileDrop}
                  onRemove={() => setFile(null)}
                  onRemoveAll={() => setFile(null)}
                  showPreview={false}
                  showSelectedFiles={true}
                  multiple={false}
                />
                {selectedConcatenatedDocumentType && (
                  <Stack direction="column" justifyContent="right">
                    <Box>
                      <Typography variant="body2">Uploaded document will be saved to</Typography>
                      <Typography variant="subtitle2">{`${selectedConcatenatedDocumentType.fullPath} ${
                        additionalDescription ? `- ${additionalDescription}` : ""
                      }`}</Typography>
                    </Box>
                  </Stack>
                )}
              </Stack>
            </CardContent>
          </Card>
          <Card sx={{ width: "100%" }}>
            <CardContent>
              <Typography variant="body2" marginBottom={1}>
                Or <strong>Choose a document from Momentum</strong>
              </Typography>
              <OrderAttachments
                selectedAttachments={selectedAttachments}
                setSelectedAttachments={setSelectedAttachments}
                orderNumber={selectedPolicy?.orderNumber}
              />
            </CardContent>
          </Card>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={(!selectedConcatenatedDocumentType || !file) && selectedAttachments.length === 0}
          onClick={save}
        >
          {submitting ? "Saving...." : "Save"}
        </Button>
        {validationError && <Typography color="red">Invalid policy number</Typography>}
      </DialogActions>
    </Dialog>
  );
}
