import { Autocomplete, Box, Card, CardContent, CardHeader, Grid, Stack, TextField, Typography } from "@mui/material";
import { Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useState } from "react";
import validateEmail from "../../../utils/validateEmail";
import { AddEditContactContext } from "./AddEditContactContext";
import PhoneForm from "./PhoneForm";
import RepForm from "./RepForm";
import { getDomainFromEmail } from "../../../utils/getDomainFromEmail";
import { predefinedDomains } from "../../../utils/predefinedDomains";
import AccountRepForm from "./AccountRepForm";
import { object } from "yup";
import axios from "axios";
import { Contact } from "../../types";

interface IProps {
  refProp: MutableRefObject<null | HTMLInputElement>;
  theInput: string;
  setTheInput: Dispatch<SetStateAction<string>>;
  searchClientDuplicates: () => Promise<void>;
  emailExistsError: boolean;
  setEmailExistsError: Dispatch<SetStateAction<boolean>>;
  isMerge: boolean;
}

const getDefaultNameValues = (defaultNameString = "") => {
  const nameArray = defaultNameString.split(" ");
  if (nameArray.length == 0) {
    return { firstName: "", middleName: "", lastName: "" };
  }
  if (nameArray.length == 1) {
    return { firstName: nameArray[0], middleName: "", lastName: "" };
  }
  if (nameArray.length == 2) {
    return { firstName: nameArray[0], middleName: "", lastName: nameArray[1] };
  }
  const [firstName, middleName, ...lastArr] = nameArray;
  return { firstName, middleName, lastName: lastArr.join(" ") };
};

export default function OfficeInformation({
  searchClientDuplicates,
  refProp,
  theInput,
  emailExistsError,
  setEmailExistsError,
  isMerge
}: IProps) {
  const {
    handleContactChange,
    handleContactRoleChange,
    contact,
    editMode,
    submissionAttempted,
    formIsInvalid,
    setCompany,
    company,
    isDiscrepancyMode
  } = useContext(AddEditContactContext);

  const [isValid, setIsValid] = useState(true);

  const [existingEmailContacts, setExistingEmailContacts] = useState<Contact[]>([]);

  const setWebsiteBasedOnEmail = () => {
    if (contact?.contactRoles[0]?.businessEmail === null || company.website) {
      return;
    }
    const domain = getDomainFromEmail(contact.contactRoles[0].businessEmail);
    if (domain) {
      const isExcludedDomain = predefinedDomains.includes(domain);
      if (isExcludedDomain) {
        return;
      } else {
        setCompany((draft) => {
          draft.website = domain;
        });
      }
    }
  };
  const checkIfEmailExists = async (email: string) => {
    const { data } = await axios.get(`/api/clientphonebook/contacts/checkIfEmailExists?email=${email}`);
    if (email !== "") {
      setEmailExistsError(data.emailExists);
      setExistingEmailContacts(data.contacts);
    } else {
      setEmailExistsError(false);
      setExistingEmailContacts([]);
    }
  };
  useEffect(() => {
    /////TODO Using a set timeout here is probably not the right approach, and should probably be fixed
    /////It causes this function in the useEffect to be put at the end of the stack even though there are others in the context
    setTimeout(() => {
      const searchParams = new URLSearchParams(location.search);
      const defaultName = searchParams.get("defaultname") || "";
      const { firstName, middleName, lastName } = getDefaultNameValues(defaultName);
      if (firstName) {
        handleContactChange("firstName", firstName);
      }
      if (middleName) {
        handleContactChange("middleName", middleName);
      }
      if (lastName) {
        handleContactChange("lastName", lastName);
      }
    }, 0);
  }, []);

  return (
    <Card>
      <CardHeader title="Profile" />
      <CardContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <Grid item md={2.5} xs={12}>
            <Autocomplete
              value={contact.salutation || ""}
              options={["Dr.", "Mr.", "Mrs.", "Ms.", "Rabbi"]}
              onChange={(e, value) => handleContactChange("salutation", value?.trim())}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password"
                  }}
                  fullWidth
                  label="Title"
                />
              )}
            />
          </Grid>
          <Grid item md={2.75} xs={12}>
            <TextField
              value={contact.firstName || ""}
              inputRef={theInput === "First Name" ? refProp : null}
              label="First Name"
              onChange={(e) => handleContactChange("firstName", e.target.value.trim())}
              fullWidth
              onBlur={() => {
                searchClientDuplicates();
              }}
              inputProps={{
                autoComplete: "new-password"
              }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              value={contact.middleName || ""}
              label="Middle"
              onChange={(e) => handleContactChange("middleName", e.target.value.trim())}
              fullWidth
              inputProps={{
                autoComplete: "new-password"
              }}
            />
          </Grid>
          <Grid item md={2.75} xs={12}>
            <TextField
              error={submissionAttempted && !contact.lastName}
              value={contact.lastName || ""}
              inputRef={theInput === "Last Name" ? refProp : null}
              label="Last Name"
              onChange={(e) => handleContactChange("lastName", e.target.value.trim())}
              fullWidth
              onBlur={() => {
                searchClientDuplicates();
              }}
              inputProps={{
                autoComplete: "new-password"
              }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              value={contact.suffix || ""}
              label="Suffix"
              onChange={(e) => handleContactChange("suffix", e.target.value)}
              fullWidth
              inputProps={{
                autoComplete: "new-password"
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <Typography variant="subtitle2" marginBottom={2}>
              Communication
            </Typography>
            <TextField
              value={contact.contactRoles[0]?.businessEmail || ""}
              inputRef={theInput === "Business Email" ? refProp : null}
              label="Business Email"
              error={!!(!isValid && contact.contactRoles[0]?.businessEmail && submissionAttempted && formIsInvalid)}
              helperText={!isValid && contact.contactRoles[0]?.businessEmail && "Invalid Email"}
              onFocus={() => setIsValid(true)}
              onChange={(e) => {
                setIsValid(true);
                handleContactRoleChange("businessEmail", e.target.value.trim());
              }}
              fullWidth
              onBlur={(e) => {
                searchClientDuplicates();
                if (e.target.value.trim() !== "") {
                  setIsValid(validateEmail(e.target.value));
                }
                setWebsiteBasedOnEmail();
                checkIfEmailExists(e.target.value);
              }}
              inputProps={{
                autoComplete: "new-password"
              }}
            />
          </Grid>
          {(emailExistsError && !isMerge) && (
            <Typography color={"#ff5252"} marginLeft={2}>
              {existingEmailContacts.map((c) => {
                return (
                  <div>
                    This email
                    <span style={{ padding: "0 4px" }}>is already in use by phonebook contact</span>
                    <a
                      target="_blank"
                      referrerPolicy="no-referrer"
                      href={`../phonebook/contact/profile?id=${c.id}`}
                      style={{ textDecoration: "none" }}
                    >{`${c.firstName} ${c.lastName}`}</a>
                  </div>
                );
              })}
            </Typography>
          )}
          {contact.contactRoles[0]?.phoneNumbers?.map((p, index) => (
            <Grid item xs={12} key={p.key}>
              <PhoneForm
                theInput={theInput}
                refProp={refProp}
                index={index}
                showAddButton={contact.contactRoles[0].phoneNumbers.length - 1 === index}
                showDeleteButton={contact.contactRoles[0].phoneNumbers.length > 1}
                phone={p}
                searchClientDuplicates={searchClientDuplicates}
              />
            </Grid>
          ))}
          {!editMode && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" marginBottom={2}>
                Representatives
              </Typography>
              <Stack rowGap={2}>
                {contact.contactReps
                  ?.filter((s) => s.contactRepTypeId === 1)
                  .map((r, index) => (
                    <RepForm
                      index={index}
                      keyProp={r.key}
                      indexOfList={contact.contactReps.findIndex(
                        (cr) => cr.internalUserId === r.internalUserId && cr.contactRepTypeId === 1
                      )}
                    />
                  ))}
              </Stack>
            </Grid>
          )}

          {isDiscrepancyMode && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" marginBottom={2}>
                Account Reps
              </Typography>
              <Box width="50%">
                <AccountRepForm />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
