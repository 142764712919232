import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import Count from "./Count";
import ReassignButtonAndModal from "../ReassignButtonAndModal";
import { DashboardReportType } from "../../checks/types";
import { fCurrency } from "../../utils/formatNumber";

interface IProps {
  assignedToMe: boolean;
  setAssignedToMe: Dispatch<SetStateAction<boolean>>;
  count: number | null;
  total?: number;
  hideTotal: boolean;
  hideAssignedToMeOptions: boolean;
  hideReasignAction?: boolean;
  table: any;
  fetchTableData: Function;
  reportType: DashboardReportType;
  onRowsDeselected?: () => void;
}

export default function Actions({
  assignedToMe,
  setAssignedToMe,
  count,
  total,
  hideTotal,
  hideAssignedToMeOptions,
  hideReasignAction,
  reportType,
  table,
  fetchTableData,
  onRowsDeselected,
}: IProps) {
  return (
    <Stack direction="row" spacing={4} alignItems="center">
      <Count count={count} />
      {!hideTotal && (
        <Box>
          <Typography color="#637381" display="inline-block" variant="subtitle1" marginRight={1}>
            Total:
          </Typography>
          <Typography variant="body1" display="inline-block">
            {total ? fCurrency(total) : 0}
          </Typography>
        </Box>
      )}
      {!hideAssignedToMeOptions && (
        <FormControlLabel
          control={<Checkbox checked={assignedToMe} onChange={(_, value) => setAssignedToMe(value)} />}
          label="Assigned to me"
        />
      )}
      
      {!hideAssignedToMeOptions && !hideReasignAction && (
        <ReassignButtonAndModal
          table={table}
          fetchTableData={fetchTableData}
          reportType={reportType}
          onRowsDeselected={onRowsDeselected}
        />
      )}
    </Stack>
  );
}
