import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { DateRange, DateRangePicker, LocalizationProvider, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useContext } from "react";
import { DashboardReportType } from "./types";
import ReassignButtonAndModal from "../../ReassignButtonAndModal";
import { PolicyIssuesContext } from "./PolicyIssuesContext";

interface IProps {
  dateRange: DateRange<dayjs.Dayjs>;
  setDateRange: Dispatch<SetStateAction<DateRange<dayjs.Dayjs>>>;
  assignedToMe: boolean;
  setAssignedToMe: Dispatch<SetStateAction<boolean>>;
  table: any;
  hideAssignedToMeOptions: boolean;
  onRowsDeselected: () => void;
}

export default function PolicyIssuesActions({
  dateRange,
  setDateRange,
  assignedToMe,
  setAssignedToMe,
  table,
  hideAssignedToMeOptions,
  onRowsDeselected
}: IProps) {
  const { fetchPolicies } = useContext(PolicyIssuesContext);
  return (
    <Stack direction="row" spacing={4} alignItems="center">
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker
          value={dateRange}
          localeText={{
            start: "Transaction-Start-Date",
            end: "Transaction-End-Date"
          }}
          onAccept={(newValue) => {
            setDateRange(newValue);
          }}
          format="MM/DD/YY"
          calendars={3}
          slotProps={{ textField: { size: "small" } }}
          slots={{ field: SingleInputDateRangeField }}
          sx={{ width: 220 }}
        />
      </LocalizationProvider> */}
      {/* <TotalAmount table={table} /> */}
      {!hideAssignedToMeOptions && (
        <FormControlLabel
          control={<Checkbox checked={assignedToMe} onChange={(_, value) => setAssignedToMe(value)} />}
          label="Assigned to me"
        />
      )}
      <ReassignButtonAndModal
        table={table}
        fetchTableData={fetchPolicies}
        reportType={DashboardReportType.PoliciesToSend}
        onRowsDeselected={onRowsDeselected}
      />
    </Stack>
  );
}
